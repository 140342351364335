import React, { useEffect, useState } from "react";
import NavigationHeader from "../NavigationHeader/index";
import { DeleteTwoTone, EditFilled } from "@ant-design/icons";
import { Table, Space, message, Popconfirm, Button } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../../api/common";
import { useHistory } from "react-router";

export const ListViews = () => {
   const [{ data, loading, error }, refetch] = useAxios(
      Resource.path.getFormTemplates
   );
   const [templates, setTemplates] = useState<any>([])
   const history = useHistory();

   const [, executeDelete] = useAxios(
      {
         method: "delete"
      },
      { manual: true }
   );

   useEffect(()=>{
      if(data?.data?.data){
         setTemplates([...templates, ...data.data.data])
      }
   }, [data]);  

   const columns: any = [
      {
         title: "Template Name",
         dataIndex: "name",
         key: "name",
         width: 150,
         ellipsis: true,
         fixed: "left"
      },
      {
         title: "Description",
         dataIndex: "description",
         key: "description",
         width: 250
      },
      {
         title: "Template",
         dataIndex: "template",
         key: "template",
         width: 500,
         maxHeight: 20,
         render: (schema: any) => {
            return <span>{JSON.stringify(schema)}</span>;
         },
         ellipsis: true
      },
      {
         title: "Action",
         key: "action",
         width: 60,
         fixed: "right",

         render: (record: any) => (
            <Space size="middle">
               <Popconfirm
                  title="Are you sure to delete this form template ?"
                  onConfirm={() => onDeleteItem(record.product_group_id)}
                  okText="Yes"
                  cancelText="No">
                  <DeleteTwoTone />
               </Popconfirm>
               <EditFilled onClick={() => onItemSelectedForEdit(record)} />
            </Space>
         )
      }
   ];

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page</p>;

   
   const onDeleteItem = (productGroupId: any) => {
      executeDelete({
         url: `${Resource.path.deleteFormTemplateById}/${productGroupId}`
      }).then(() => {
         setTemplates([]);
         refetch();
         message.success("Form Template deleted successfully.");
      });
   };

   const onItemSelectedForEdit = (record: any) => {
      history.push({
         pathname: `/forms/templates/edit/${record.product_group_id}`,
         state: { field: record }
      });
   };

   const Footer = () => {
      return <>
         {
            data?.data?.meta?.hasMoreData && <Button type="primary" onClick={async () => {
               await refetch({
                  url: `${Resource.path.getFormTemplates}?cursor=${data?.data?.meta?.cursor}`
               })
            }}>Load More</Button>
         }
      </>
   }

   return (
      <>
         <NavigationHeader></NavigationHeader>
         <Table
            loading={loading}
            pagination={false}
            dataSource={templates}
            columns={columns}
            scroll={{ x: 1300 }}
            rowKey={(record) => record.id}
            footer={()=><Footer/>}
            ></Table>
      </>
   );
};
