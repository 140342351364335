import { CheckboxFieldSchema } from "../../../../components/FormFields/CheckboxField/index";
import { ColorSwatchFieldSchema } from "../../../../components/FormFields/ColorSelectField/index";
import { DateFieldSchema } from "../../../../components/FormFields/DateField/index";
import { NumberFieldSchema } from "../../../../components/FormFields/NumberField/index";
import { RadioFieldSchema } from "../../../../components/FormFields/RadioField";
import { SelectFieldSchema } from "../../../../components/FormFields/SelectField/index";
import { SliderFieldSchema } from "../../../../components/FormFields/SliderField";
import { SwitchFieldSchema } from "../../../../components/FormFields/SwitchField/index";
import { TextFieldSchema } from "../../../../components/FormFields/TextField/index";
import { UnitFieldSchema } from "../../../../components/FormFields/UnitField/index";

export const isValidSchema = (schema: any) => {
   if (typeof schema !== "object") return false;

   if (Array.isArray(schema)) {
      // Array of schema is not being supported yet
      return false;
   } else {
      if (schema.field?.type) {
         let validationResult;
         switch (schema.field?.type) {
            case "text":
               validationResult = TextFieldSchema.validate(schema);
               break;
            case "date":
               validationResult = DateFieldSchema.validate(schema);
               break;
            case "number":
               validationResult = NumberFieldSchema.validate(schema);
               break;
            case "switch":
               validationResult = SwitchFieldSchema.validate(schema);
               break;
            case "select":
               validationResult = SelectFieldSchema.validate(schema);
               break;
            case "slider":
               validationResult = SliderFieldSchema.validate(schema);
               break;
            case "radio":
               validationResult = RadioFieldSchema.validate(schema);
            case "checkbox":
               validationResult = CheckboxFieldSchema.validate(schema);
               break;
            case "unitfield":
               validationResult = UnitFieldSchema.validate(schema);
               break;
            case "colorswatchfield":
               validationResult = ColorSwatchFieldSchema.validate(schema);
               break;
            default:
               return false;
         }

         return !validationResult.error;
      } else {
         return false;
      }
   }
};
