import { CheckboxField } from "./CheckboxField";
//import { ColorSwatchField } from "./ColorSwatchField";
import { ColorSwatchField } from "./ColorSelectField";
import { DateField } from "./DateField";
import { NumberField } from "./NumberField";
import { RadioField } from "./RadioField";
import { SelectField } from "./SelectField";
import { SliderField } from "./SliderField";
import { SwitchField } from "./SwitchField";
import { TextField } from "./TextField";
import { UnitField } from "./UnitField";

export const FormElementFieldMap = new Map();

FormElementFieldMap.set("text", TextField);
FormElementFieldMap.set("date", DateField);
FormElementFieldMap.set("number", NumberField);
FormElementFieldMap.set("switch", SwitchField);
FormElementFieldMap.set("select", SelectField);
FormElementFieldMap.set("slider", SliderField);
FormElementFieldMap.set("radio", RadioField);
FormElementFieldMap.set("checkbox", CheckboxField);
FormElementFieldMap.set("unitfield", UnitField);
FormElementFieldMap.set("colorswatchfield", ColorSwatchField);
