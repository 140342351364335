import React from "react";
import { Material } from "../views/ProductGroups/AddEditProductGroups/types";

type MaterialGroups = {
   [key: string]: Material[];
};

export function useMaterialGroups(materials?: Material[]) {
   const [materialGroups, setMaterialGroups] = React.useState<MaterialGroups>(
      {}
   );
   React.useEffect(() => {
      if (materials && materials.length > 0) {
         setMaterialGroups(
            materials.reduce<MaterialGroups>((previous, current) => {
               previous[current.de_group_name] =
                  previous[current.de_group_name] || [];

               if (
                  !previous[current.de_group_name].find(
                     (a) => a.de_material_name === current.de_material_name
                  )
               ) {
                  previous[current.de_group_name].push(current);
               }

               return previous;
            }, {})
         );
      }
   }, [materials]);
   return materialGroups;
}
