import React from "react";
import { FormElementFieldMap } from "../../../components/FormFields/FormElementFieldMap";

export const layout = {
   labelCol: { span: 8 },
   wrapperCol: { span: 16 }
};

export const FormView = React.memo(({ schema }: any) => {
   const elements = schema.map((item: any) => {
      const TagName = FormElementFieldMap.get(item.field.type);
      return (
         <div className="form-elements-wrapper">
            <TagName key={item.formItem.name} {...item}></TagName>
         </div>
      );
   });

   return <>{elements}</>;
});
