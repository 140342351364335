import React, { useState } from "react";
import ActionHeader from "./ActionHeader/ActionHeader";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { UsersTable } from "./UsersTable";
import { Button } from "antd";

export const UsersViews = () => {
   const [paginationToken, setPaginationToken] = useState("");
   const [usersData, setUsersData] = useState<any>(null);

   const [{ data, loading, error }, refetch] = useAxios(
      `${Resource.path.getAsterixAppUsers}?cursor=${encodeURIComponent(
         paginationToken
      )}`
   );

   React.useEffect(() => {
      if (data?.data) {
         if (usersData && usersData?.length) {
            setUsersData([...usersData, ...data?.data?.Users]);
            console.log(usersData);
         } else {
            setUsersData(data?.data?.Users);
         }
      }
   }, [data]);

   if (loading) return <p>Loading...</p>;
   if (!usersData) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page</p>;

   const handleOnSubmitSuccess = async() => {
     setUsersData([]); 
     await refetch();
   };

   const onLoadMore = () => {
      setPaginationToken(data?.data?.PaginationToken);
   };

   return (
      <>
         <ActionHeader onSubmitSuccess={handleOnSubmitSuccess}></ActionHeader>
         <UsersTable
            users={usersData}
            loading={loading}
            refetch={handleOnSubmitSuccess}
         />

         {data?.data?.PaginationToken && (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
               <Button type="primary" onClick={onLoadMore}>
                  Load More
               </Button>
            </div>
         )}
      </>
   );
};
