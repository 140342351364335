import { Badge, Image, Space, Table } from "antd";
import React from "react";
import { EditTwoTone, DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";

const columns: any = [
   {
      title: "Image",
      dataIndex: "product_images",
      key: "product_images",
      width: 150,
      fixed: "left",
      render: (images: any) => {
         if (images && images.length) {
            const [firstImageObj] = images;
            return (
               <>
                  {images.length > 1 ? (
                     <Badge count={images.length - 1}>
                        <Image
                           src={firstImageObj.object_url}
                           width={60}></Image>
                     </Badge>
                  ) : (
                     <Image src={firstImageObj.object_url} width={60}></Image>
                  )}
               </>
            );
         }
      }
   },
   {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 250,
      fixed: "left"
   },
   {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 500
   },
   {
      title: "Metafield",
      dataIndex: "metafield",
      key: "metafield",
      width: 300
   },
   {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 500,
      maxHeight: 20,
      render: (schema: any) => {
         return <span>{JSON.stringify(schema)}</span>;
      },
      ellipsis: true
   },
   {
      title: "Action",
      key: "action",
      width: 130,
      fixed: "right",

      render: () => (
         <Space size="middle">
            <EditTwoTone /> <DeleteTwoTone /> <EyeTwoTone />
         </Space>
      )
   }
];

export const ProductsTable = ({ data }: any) => {
   return (
      <Table
         pagination={false}
         dataSource={data}
         columns={columns}
         scroll={{ x: 1300 }}
         rowKey={(record) => record.id}></Table>
   );
};
