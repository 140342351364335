import React from "react";
import { Form, Select } from "antd";
import * as Joi from "joi";

export const SelectFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("select"),
      options: Joi.array().required()
   }).unknown()
});

export interface SelectField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      [key: string]: any;
   };
}

const getOptionsElement = (options: any) => {
   return (
      options &&
      options.map((option: any) => {
         return (
            <Select.Option key={option} value={option}>
               {option}
            </Select.Option>
         );
      })
   );
};

export const SelectField = (props: SelectField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   const optionsElm = getOptionsElement(fieldProps.options);
   const selectFieldProps = Object.assign({}, { ...fieldProps });

   // Remove unsupported properties so that generic Select work as expected
   Reflect.deleteProperty(selectFieldProps, "options");

   return (
      <Form.Item {...formItemProps}>
         <Select {...selectFieldProps}>{optionsElm}</Select>
      </Form.Item>
   );
};
