import { Resource } from "../../../api/common";
import * as uuid from "uuid";
import axios from "axios";
import { Auth } from "aws-amplify";

export async function getUploadedProductImagesLinks(values: any) {
   const assetId = `${uuid.v4()}_${Date.now()}`;
   const assetType = "product_image";
   const productImages: any[] = [];
   const accessToken = (await Auth.currentSession()).getIdToken();

   const signedUrlPromiseList = values.productImages.map(async (item: any) => {
      // Get Upload Signed URL
      const response = await axios.post(
         Resource.path.getSignedUrlForProductAssets,
         {
            assetType,
            assetId,
            fileName: item.name
         },
         { headers: { Authorization: accessToken.getJwtToken() } }
      );
      const result = response?.data?.data;
      if (result) {
         return axios.put(result.signedUrl, item.originFileObj).then(() => {
            productImages.push({
               file_name: result.fileName,
               file_path: result.filePath,
               object_url: result.objectUrl
            });
         });
      }
   });

   await Promise.all(signedUrlPromiseList);
   return productImages;
}
