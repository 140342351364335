import React, { useState } from "react";
import { Col, Row, Input, Select, Button, Form, Switch } from "antd";

import Text from "antd/lib/typography/Text";
import { GwpForm } from "./GwpForm";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useUnitFields } from "../../../hooks/useUnitFields";
import { ConversionForm } from "./ConversionForm";
import { FormTemplate, Response } from "./types";

type ProductGroupForm = {
   children: React.ReactNode;
   onFinish: (data: any) => void;
   initialValue: any;
   form: any;
};

export const ProductGroupForm = ({
   children,
   onFinish,
   initialValue,
   form
}: ProductGroupForm) => {

   const [{ data: formTemplatesData }] = useAxios<Response<FormTemplate>>(
      Resource.path.getFormTemplates
   );
   //console.log(initialValue)
   const formTemplates = formTemplatesData?.data.data;
   const unitFields = useUnitFields(initialValue?.id, formTemplates);
   const [visibility, setVisiblity] = useState(initialValue?.visibility_status !== undefined ? initialValue?.visibility_status : 1);
   return (
      <Form
         layout="vertical"
         onFinish={onFinish}
         name="product_group_form"
         form={form}
         initialValues={initialValue}>
         <Row gutter={16}>
            <Col span="12">
               <Form.Item
                  label="Product Group Name"
                  name="name"
                  rules={[
                     {
                        required: true,
                        message: "Please enter product group name"
                     }
                  ]}>
                  <Input placeholder="Product Group Name" size="large" />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item label="Product Group Description" name="description">
                  <Input.TextArea
                     rows={4}
                     placeholder="Product Group Description"></Input.TextArea>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item label="Unit" name="unit">
                  <Select placeholder="Unit" allowClear size="large">
                     <Select.Option value="Stück" key="Stück">
                        Stück
                     </Select.Option>
                     <Select.Option value="m" key="m">
                        m
                     </Select.Option>
                     <Select.Option value="mm" key="mm">
                        mm
                     </Select.Option>
                     <Select.Option value="mm²" key="mm²">
                        mm²
                     </Select.Option>
                     <Select.Option value="cm" key="cm">
                        cm
                     </Select.Option>
                     <Select.Option key="kg/dm³" value="kg/dm³">
                        kg/dm³
                     </Select.Option>
                     <Select.Option key="kg/m²" value="kg/m²">
                        kg/m²
                     </Select.Option>
                     <Select.Option key="lfm" value="lfm">
                        lfm
                     </Select.Option>
                     <Select.Option key="m²" value="m²">
                        m²
                     </Select.Option>
                     <Select.Option key="m³" value="m³">
                        m³
                     </Select.Option>
                     <Select.Option key="t" value="t">
                        t
                     </Select.Option>
                     <Select.Option key="L" value="L">
                        L
                     </Select.Option>
                     <Select.Option key="N/mm²" value="N/mm²">
                        N/mm²
                     </Select.Option>
                     <Select.Option key="piece/s" value="piece/s">
                        Piece/s
                     </Select.Option>
                  </Select>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="10">
               <p>High</p>

               <Form.Item label="Min" name="high_min">
                  <Input
                     type="number"
                     addonBefore="min"
                     addonAfter="€"
                     size="large"></Input>
               </Form.Item>
               <Form.Item label="Max" name="high_max">
                  <Input
                     type="number"
                     addonBefore="max"
                     addonAfter="€"
                     size="large"></Input>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="10">
               <Text>Medium</Text>

               <Form.Item label="Min" name="medium_min">
                  <Input
                     type="number"
                     addonBefore="min"
                     addonAfter="€"
                     size="large"></Input>
               </Form.Item>
               <Form.Item label="Max" name="medium_max">
                  <Input
                     type="number"
                     addonBefore="max"
                     addonAfter="€"
                     size="large"></Input>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="10">
               <p>Low</p>

               <Form.Item label="Min" name="low_min">
                  <Input
                     type="number"
                     addonBefore="min"
                     addonAfter="€"
                     size="large"></Input>
               </Form.Item>
               <Form.Item label="Max" name="low_max">
                  <Input
                     type="number"
                     addonBefore="max"
                     addonAfter="€"
                     size="large"></Input>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item label="Expected life time" name="expected_life_time">
                  <Input
                     type="text"
                     placeholder="in years"
                     addonAfter="years"
                     size="large"></Input>
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span="10">
               <Form.Item label="Deconstruction costs" name="deconstruction_costs">
                  <Input
                     min="0"
                     type="number"
                     addonAfter="€"
                  />
               </Form.Item>
            </Col>
         </Row>

         <GwpForm form={form} savedData={initialValue}/>

         <Row gutter={16}>
            <Col span="9">
               <Form.Item label="Meta label" name={"lca_meta_label"}>
                  <Input type="text" size="large"></Input>
               </Form.Item>
            </Col>
         </Row>

         {unitFields && (
            <ConversionForm
               form={form}
               unitFields={unitFields}
               currentValues={initialValue.conversion}
            />
         )}

         <Row gutter={16}>
            <Col span="18">
               <Form.List name="lca_meta">
                  {(fields, { add, remove }) => (
                     <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                           <>
                              <GwpForm
                                 name={name}
                                 form={form}
                                 currentValues={initialValue?.lca_meta}
                              />
                              <MinusCircleOutlined
                                 className="dynamic-delete-button"
                                 onClick={() => remove(name)}
                              />
                           </>
                        ))}
                        <Form.Item>
                           <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}>
                              Add material
                           </Button>
                        </Form.Item>
                     </>
                  )}
               </Form.List>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="10">
               <Form.Item label="Visibility" name="visibility_status">
                  <Switch checkedChildren="Show" unCheckedChildren="Hide" checked={visibility} onChange={()=>{
                     setVisiblity(!visibility);
                  }} />
               </Form.Item>
            </Col>
         </Row>
         {children}
      </Form>
   );
};
