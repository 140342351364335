import { message } from "antd";
import { SaveFormTemplateHandler } from "./interface";

export function getSaveFormTemplateHandler({
   form,
   executePost,
   executePut,
   productGroupId,
   templateSchema,
   history,
   isEditMode,
   formTemplateId
}: SaveFormTemplateHandler) {
   return (values: any) => {
      form.validateFields();
      const key = "addFormTemplateLoadingKey";
      message.loading({ content: "Loading...", key });

      if (isEditMode) {
         executePut({
            data: {
               id: formTemplateId,
               product_group_id: productGroupId,
               name: values.name,
               description: values.description,
               template: templateSchema
            }
         }).then(() => {
            message.success(
               {
                  content: "Form Template updated successfully",
                  key
               },
               0
            );

            setTimeout(() => {
               message.destroy(key);
               history.push("/forms/templates");
            }, 100);
         });
      } else {
         executePost({
            data: {
               product_group_id: productGroupId,
               name: values.name,
               description: values.description,
               template: templateSchema
            }
         }).then(() => {
            message.success(
               {
                  content: "Form Template saved successfully",
                  key
               },
               0
            );

            setTimeout(() => {
               message.destroy(key);
               history.push("/forms/templates");
            }, 100);
         });
      }
   };
}
