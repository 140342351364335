import React, { useEffect, useState } from "react";
import { Drawer, Tree, Spin, Space, Button, Divider, message } from 'antd';
import {
    GoldOutlined,
    GroupOutlined
} from '@ant-design/icons'
import useAxios from "axios-hooks";
import * as uuid from "uuid";
import { Resource } from "../../api/common";


export const Mapping = ({ visible, selected, onClose }: any) => {
    const [catData, setCatData] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [customExpandedKeys, setCustomExpandedKeys] = useState<any>([]);
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<any>([]);
    const [showSave, setShowSave] = useState<any>(true);
    const [newMapping, setNewMapping] = useState<any>([]);
    const [existingMapping, setExistingMapping] = useState<any>([]);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    //console.log('selected',selected)
    let i = 0;
    const treeCustom: any = [];

    const [
        { data, loading: loadingCategories }, fetch
    ] = useAxios(
        {
            url: `${Resource.path.getCategories}`,
            method: "GET"
        },
        {
            useCache: false,
            manual: false
        }
    );

    const [
        { }, updateCatMapping
    ] = useAxios(
        {
            url: `${Resource.path.updateCatMappingOnPGChange}`,
            method: "POST"
        },
        {
            useCache: false,
            manual: true
        }
    );
    const onDrop = (info: any) => {
        const dropKey = info.node.key;
        if (info.dropToGap) {
            return false;
        }

        if (!info.node.allowDrop) {
            return false;
        }
        const droppedCategoryId = dropKey.split('#')[1];
        const dragKey = info.dragNode.key;
        if (!info.dragNode.draggable) {
            return false;
        }
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const loop = (data: any, key: any, callback: any) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const data = [...treeData];
        let dragObj: any;
        loop(data, dragKey, (item: any, index: any, arr: any) => {
            arr.splice(index, 1);
            dragObj = item;
        });
        if (!info.dropToGap) {
            loop(data, dropKey, (item: any) => {
                item.children = item.children || [];
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node?.props.children || []).length > 0 &&
            info.node?.props.expanded &&
            dropPosition === 1
        ) {
            loop(data, dropKey, (item: any) => {
                item.children = item.children || [];
                item.children.unshift(dragObj);
            });
        } else {
            let ar: any;
            let i: any;
            loop(data, dropKey, (item: any, index: any, arr: any) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }
        setTreeData(data)
        //console.log('newParent', droppedCategoryId)
        setNewMapping((newMapping: any) => [...newMapping, { category_id: droppedCategoryId, product_group_id: selected.id }]);
        setShowSave(false)
    }

    const traverse = (node: any, item: any, isProductGroup: boolean = false) => {
        node.map((rec: any) => {
            const nodeItem: any = {
                isLeaf: isProductGroup ? true : false,
                title: isProductGroup ? rec.label : (rec.name ? rec.name : rec.title),
                icon: isProductGroup ? <GroupOutlined /> : <GoldOutlined />,
                key: isProductGroup ? `pg#${rec.key}#${uuid.v4()}` : `cat#${rec.id}#${uuid.v4()}`,
                draggable: isProductGroup && rec.key === selected.id,
                allowDrop: !isProductGroup,
            }
            if (isProductGroup && rec.key === selected.id) {
                setDefaultSelectedKeys((defaultSelectedKeys: any) => [...defaultSelectedKeys, nodeItem.key])
            }
            setCustomExpandedKeys((customExpandedKeys: any) => [...customExpandedKeys, nodeItem.key])
            if (rec?.children) {
                if (nodeItem.children === undefined) {
                    nodeItem.children = [];
                }
                traverse(rec.children, nodeItem.children);
            }
            if (rec?.product_groups) {
                const itemExists = rec.product_groups.filter((record: any) => {
                    return record.key == selected.id;
                });
                if (itemExists && itemExists.length) {
                    setExistingMapping((existingMapping: any) => [...existingMapping, rec.id]);
                }
                if (nodeItem.children === undefined) {
                    nodeItem.children = [];
                }
                traverse(rec.product_groups, nodeItem.children, true);
            }
            item.push(nodeItem)
        });
    };




    const onSave = async () => {
        setSaveLoading(true);
        const categoryMapping: any = [];

        const findCategoryMapping = (category_id: any) => {
            const data = catData.filter((rec: any) => {
                return rec.id == category_id;
            });
            if (data && data.length) {
                const record: any = data.pop();
                categoryMapping.push(record.id)
                if (record?.parent_id) {
                    findCategoryMapping(record?.parent_id);
                }
            }
        }
        if (existingMapping.length > 1) {
            message.error('Please remove one to many mapping. One product group must map to one sub-category/category.')
            return false;
        }

        const newParentCategoryId = newMapping.pop().category_id;
        findCategoryMapping(newParentCategoryId)
        const newMappingObject: any = {}
        if (categoryMapping.length) {
            categoryMapping.map((rec: any, index: number) => {
                if (categoryMapping[index + 1]) {
                    newMappingObject[categoryMapping[index + 1]] = rec;
                } else {
                    newMappingObject['root'] = rec;
                }

            });
        }
        console.log(newMappingObject)
        await updateCatMapping({
            data: {
                existing: existingMapping.pop(),
                new: newParentCategoryId,
                mapping: newMappingObject,
                product_group_id: selected.id,
                product_name: selected.name
            }
        }).then((resp) => {
            message.success("Mapping updated successfully");
            onClose();
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        if (data?.data) {
            if (data?.data?.treeData) {
                traverse(data?.data?.treeData, treeCustom);
                setTreeData(treeCustom);
            }
            if (data?.data?.data) {
                setCatData(data?.data?.data);
            }
        }
    }, [data])

    return (<>
        <Drawer width={600} title="Mapping" placement="right" closable onClose={onClose} visible={visible}>
            <Spin tip="Loading..." spinning={loadingCategories}>
                <Tree
                    treeData={treeData}
                    expandedKeys={customExpandedKeys}
                    onExpand={(expandedKeys: any) => {
                        setCustomExpandedKeys(expandedKeys);
                    }}
                    onDrop={onDrop}
                    draggable
                    selectedKeys={defaultSelectedKeys}
                    height={450}
                    showLine={true}
                    multiple
                />
                <Divider />
                <Space>
                    <Button style={{ float: 'right' }} onClick={onSave} disabled={showSave} size="middle">Save</Button>
                </Space>
            </Spin>
        </Drawer>
    </>)
}