import React from "react";
import { Form, Input } from "antd";
import * as AntIconComponents from "@ant-design/icons";
import Joi from "joi";

export const TextFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("text")
   }).unknown()
});

export interface TextField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      type: "text";
      [key: string]: any;
   };
}

export type AnyIconType = keyof typeof AntIconComponents;

const getIconComponent = (iconName: AnyIconType) => {
   const IconComponent: any = AntIconComponents[iconName];
   return IconComponent && <IconComponent />;
};

export const TextField = (props: TextField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   const formattedProps = {
      ...fieldProps,
      ...(fieldProps.prefix && getIconComponent(fieldProps.prefix)
         ? { prefix: getIconComponent(fieldProps.prefix) }
         : {}),
      ...(fieldProps.suffix && getIconComponent(fieldProps.suffix)
         ? { suffix: getIconComponent(fieldProps.suffix) }
         : {})
   };

   return (
      <Form.Item {...formItemProps}>
         <Input {...formattedProps} />
      </Form.Item>
   );
};
