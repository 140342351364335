import React, { useCallback } from "react";
import { Select } from "antd";
import { getFieldList } from "./getFieldList";

export const FormFieldSearchSelect = React.memo(
   ({ fields, onFieldSelect }: any) => {
      const fieldListOptions = getFieldList(fields);

      const onFieldSelected = useCallback(
         (selectedSchema: any) => {
            onFieldSelect(selectedSchema);
         },
         [fields]
      );

      return (
         <Select
            showSearch
            style={{ width: "350px" }}
            placeholder="Select or Search a field"
            size="large"
            defaultActiveFirstOption={true}
            onChange={onFieldSelected}
            filterOption={(input, option) => {
               return (
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
               );
            }}>
            {fieldListOptions}
         </Select>
      );
   }
);
