import { Space, Table, Popconfirm } from "antd";
import React from "react";
import { DeleteTwoTone, EditFilled } from "@ant-design/icons";
import { useHistory } from "react-router";

export const FieldsTable = ({ data, onDeleteItem }: any) => {
   const history = useHistory();

   const columns: any = [
      {
         title: "Field Name",
         dataIndex: "field_name",
         key: "field_name",
         width: 150,
         ellipsis: true,
         fixed: "left"
      },
      {
         title: "Description",
         dataIndex: "description",
         key: "description",
         width: 250
      },
      {
         title: "Field Schema",
         dataIndex: "field_schema",
         key: "field_schema",
         width: 500,
         maxHeight: 20,
         render: (schema: any) => {
            return <span>{JSON.stringify(schema)}</span>;
         },
         ellipsis: true
      },
      {
         title: "",
         key: "action",
         width: 60,
         fixed: "right",

         render: (record: any) => (
            <Space size="middle">
               <Popconfirm
                  title="Are you sure to delete this field ?"
                  onConfirm={() => record?.id && onConfirm(record.id)}
                  okText="Yes"
                  cancelText="No">
                  <DeleteTwoTone />
               </Popconfirm>
               <EditFilled onClick={() => onItemSelectedForEdit(record)} />
            </Space>
         )
      }
   ];

   const onConfirm = (fieldId: string) => {
      onDeleteItem(fieldId);
   };

   const onItemSelectedForEdit = (record: any) => {
      history.push({
         pathname: `/forms/fields/edit/${record.id}`,
         state: { field: record }
      });
   };

   return (
      <>
         <Table
            pagination={false}
            dataSource={data}
            columns={columns}
            scroll={{ x: 1300 }}
            rowKey={(record) => record.id}></Table>
      </>
   );
};
