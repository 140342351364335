import React from "react";
import { Select } from "antd";

import * as uuid from "uuid";
const { Option } = Select;

export const getFieldList = (fields: any) => {
   return fields.map((item: any) => {
      return (
         <Option key={uuid.v4()} value={JSON.stringify(item.field_schema)}>
            {item.field_name}
         </Option>
      );
   });
};
