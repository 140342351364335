import React from "react";
import { Checkbox, Form } from "antd";
import * as Joi from "joi";

export const CheckboxFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("checkbox"),
      options: Joi.array().required()
   }).unknown()
});

export interface CheckboxField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      [key: string]: any;
   };
}

export const CheckboxField = (props: CheckboxField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   return (
      <Form.Item {...formItemProps}>
         <Checkbox.Group {...fieldProps}></Checkbox.Group>
      </Form.Item>
   );
};
