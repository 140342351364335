import React from "react";
import "antd/dist/antd.css";
import "./app.css";

import { MainLayout } from "./components/Layouts/index";
import { Switch, Route } from "react-router-dom";
import { ProductsViews } from "./views/Products";
import { FormsViews } from "./views/Forms";
import { CategoriesViews } from "./views/Categories";
import { BrandsViews } from "./views/Brands";

import Amplify, { Auth } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { configure } from "axios-hooks";
import axios from "axios";
import { ProductGroupViews } from "./views/ProductGroups";
import { UsersViews } from "./views/AppUsers/index";
import { MaterialsViews } from "./views/Materials/index";
import { MaterialRequestViews } from "./views/MaterialRequests/index";
import { CategoriesTreeViews } from "./views/CategoriesTree/index";

Amplify.configure({
   aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
   aws_cognito_identity_pool_id:
      process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
   aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
   aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
   aws_user_pools_web_client_id:
      process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
   oauth: process.env.REACT_APP_OAUTH || {}
});

const AuthorizedAppViews = () => {
   return (
      <MainLayout>
         <Switch>
            <Route exact path="/" component={ProductsViews}></Route>
            <Route path="/products" component={ProductsViews}></Route>
            <Route path="/products-group" component={ProductGroupViews}></Route>
            <Route path="/categories/:id" component={CategoriesViews}></Route>
            <Route
               path="/categoriesTree"
               component={CategoriesTreeViews}></Route>
            <Route path="/brands" component={BrandsViews}></Route>
            <Route path="/forms" component={FormsViews}></Route>
            <Route path="/users" component={UsersViews}></Route>
            <Route path="/materials" component={MaterialsViews}></Route>
            <Route
               path="/materials_request"
               component={MaterialRequestViews}></Route>
         </Switch>
      </MainLayout>
   );
};

const App = () => {
   const [authState, setAuthState] = React.useState<AuthState>();
   const [user, setUser] = React.useState<any | undefined>();
   const [axiosTokenConfigured, setAxiosTokenConfigured] = React.useState<
      any | undefined
   >(false);

   React.useEffect(() => {
      return onAuthUIStateChange(async (nextAuthState, authData) => {
         setAuthState(nextAuthState);
         setUser(authData);
         const jwtToken = (await Auth.currentSession())
            .getIdToken()
            .getJwtToken();
         configure({
            axios: axios.create({
               timeout: 6000,
               headers: { Authorization: jwtToken }
            }),
            cache: false
         });
         setAxiosTokenConfigured(true);
      });
   }, []);

   return authState === AuthState.SignedIn && user && axiosTokenConfigured ? (
      <div className="App">
         <AuthorizedAppViews></AuthorizedAppViews>
      </div>
   ) : (
      <div className="auth-page-login-wrapper">
         <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp />
         </AmplifyAuthenticator>
         {/* This span element is required to track login page  */}
         <span id="login_page_hidden" style={{ display: "none" }}></span>
      </div>
   );
};

export default App;
