import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { Resource } from "../../../../api/common";

import { LcaMeta, Material, Response } from "../types";
import { MaterialDropdown } from "./MaterialDropdown";
import "./style.scss";

type Props = {
   form: FormInstance<any>;
   name?: number;
   selectedMaterial?: Material;
   currentValues?: Array<LcaMeta>;
   savedData?: any;
};

export const GwpForm: React.FC<Props> = ({
   form,
   name,
   currentValues,
   savedData
}: Props) => {
   const [{ data: materialsData }] = useAxios<Response<Material>>(
      Resource.path.getMaterials
   );
   const materials = materialsData?.data.data;
   const [subMaterial, _setSubMaterial] = React.useState<Material>();
   const setSubMaterial = (val: Material | undefined) => {
      _setSubMaterial(val);
      const fields = form.getFieldsValue();
      if(fields?.density_max && val){
         fields['density_max'] = val.max || val.min;
      }
      if(fields?.density_min && val){
         fields['density_min'] = val.min;
      }
      if(fields?.default_material&& val){
         fields['default_material']['sub_material_id'] = val.id;
      }
      if (name !== undefined && val) {
         fields["lca_meta"][name].max = val.max || val.min;
         fields["lca_meta"][name].min = val.min;
         fields["lca_meta"][name].sub_material_id = val.id;
      }
      form.setFieldsValue(fields);
   };
   const [showDefaultMaterial, setShowDefaultMaterial] = useState(false)
   // TODO/suggestion: would be best to move this fetching and parsing logic into some api part
   const handleObdCheck = (processUuid: string, form: FormInstance<any>) => {
      let requestValid = false;
      if (processUuid) {
         fetch(
            Resource.path.getOekobaudat(
               processUuid,
               "cd2bda71-760b-4fcc-8a0b-3877c10000a8"
            ),
            {
               method: "GET"
            }
         )
            .then((response) => {
               return response.json();
            })
            .then((data) => {
               let xmlStr = data.data;
               // cleanup the string: remove backslashes, surrounding doublequotes and obsolete tag <xml-stylesheet>
               xmlStr = xmlStr
                  .replace(/\\"/g, '"')
                  .replace(/^"|"$/g, "")
                  .replace(/<\?xml-stylesheet(.*)\?>/g, "")
                  .replace(/<\?xml version(.*)\?>/g, "")
                  .replace(/\\n/g, "")
                  .replace(/\\r/g, "");
               let parser = new DOMParser();
               let xml = parser.parseFromString(xmlStr, "text/xml");

               // Get nodes containing relevant data
               const results: Element[] = Array.from(
                  xml.getElementsByTagName("LCIAResult")
               );
               let gwpNodes = [];

               for (const el of results) {
                  // Look elements containing GWP
                  if (el!.textContent!.includes("GWP-total")) {
                     // Store the total amount of GWP and exit loop
                     gwpNodes = [el];
                     break;
                  } else if (el!.textContent!.includes("GWP")) {
                     // Store all elements that contain GWP values
                     gwpNodes.push(el);
                  }
               }

              // console.log(gwpNodes);
               if (gwpNodes.length) {
                  // Found node containing GWP
                  let epdValues = { a13: 0, c1: 0, c4: 0, c3: 0 };

                  for (const gwpNode of gwpNodes) {
                     let commonNodes =
                        gwpNode.getElementsByTagName("common:other");

                     if (commonNodes.length) {
                        // Found a node containing the value, extract from collection
                        let commonNode = commonNodes[0];
                        const epdNodes: Element[] = Array.from(
                           commonNode.children
                        );

                        for (const c of epdNodes) {
                           switch (c.getAttribute("epd:module")) {
                              case "A1-A3":
                                 requestValid = true;
                                 epdValues.a13 += parseFloat(c.innerHTML);
                                 break;

                              case "C1":
                                 requestValid = true;
                                 epdValues.c1 += parseFloat(c.innerHTML);
                                 break;

                              case "C3":
                                 requestValid = true;
                                 epdValues.c3 += parseFloat(c.innerHTML);
                                 break;

                              case "C4":
                                 requestValid = true;
                                 epdValues.c4 += parseFloat(c.innerHTML);
                                 break;
                           }
                        }
                     }
                  }

                  if (requestValid) {
                     const fields = form.getFieldsValue();
                     // This would be cleaner if we use the array for the main value too
                     if (name !== undefined) {
                        fields["lca_meta"][name].gwp_a1a3 = epdValues.a13;
                        fields["lca_meta"][name].gwp_c1 = epdValues.c1;
                        fields["lca_meta"][name].gwp_c3 = epdValues.c3;
                        fields["lca_meta"][name].gwp_c4 = epdValues.c4;
                     } else {
                        Object.assign(fields, {
                           [`gwp_a1a3`]: epdValues.a13,
                           [`gwp_c1`]: epdValues.c1,
                           [`gwp_c3`]: epdValues.c3,
                           [`gwp_c4`]: epdValues.c4
                        });
                     }

                     form.setFieldsValue(fields);
                  } else {
                     // TODO: Indicate that data could not be fetched
                     console.log("Could not find GWP data.");
                  }
               } else {
                  console.log("GWP node could not be found in data.");
               }
            })
            .catch((error) => {
               console.log(error);
            });
      }
   };
   const onChangeLcaUnit = (value: any) => {
      if (value === 'kg') {
         setShowDefaultMaterial(true);
      } else {
         setShowDefaultMaterial(false);
      }
   }

   useEffect(() => {
      if (savedData?.lca_unit === 'kg') {
         setShowDefaultMaterial(true);
      }else{
         setShowDefaultMaterial(false);
      }
   }, [savedData])

   return (
      <>
         {name !== undefined && (
            <>
               {materials && (
                  <MaterialDropdown
                     name={[name]}
                     materials={materials}
                     initial={{
                        parent_material_id:
                           currentValues?.[name]?.parent_material_id,
                        sub_material_id: currentValues?.[name]?.sub_material_id
                     }}
                     setSubMaterial={setSubMaterial}
                     setMaterialGroup={() => {
                        if (name !== undefined) {
                           const fields = form.getFieldsValue();
                           if (fields["lca_meta"]?.[name].sub_material_id) {
                              fields["lca_meta"][name].sub_material_id =
                                 undefined;
                              form.setFieldsValue(fields);
                           }
                        }
                     }}
                  />
               )}
            </>
         )}
         <Row gutter={16}>
            <Col span="12">
               <Form.Item
                  label="ÖKOBAUDAT Product UUID"
                  name={
                     name !== undefined
                        ? [name, `obdProductUuid`]
                        : `obdProductUuid`
                  }
                  hasFeedback>
                  <Input
                     type="text"
                     placeholder="826fa2c2-c691-4844-83d3-aea0e8a54bcd"
                     size="large"></Input>
               </Form.Item>
            </Col>
            <Col span="4">
               <Form.Item label="&nbsp;">
                  <Button
                     onClick={() =>
                        handleObdCheck(
                           form.getFieldValue(
                              name !== undefined
                                 ? ["lca_meta", name, `obdProductUuid`]
                                 : `obdProductUuid`
                           ),
                           form
                        )
                     }>
                     Fetch GWP
                  </Button>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item
                  label="GWP stage A1-A3"
                  rules={[
                     {
                        required: false,
                        message: "Please enter GWP stage A1-A3"
                     }
                  ]}
                  name={name !== undefined ? [name, `gwp_a1a3`] : `gwp_a1a3`}>
                  <InputNumber
                     type="number"
                     placeholder="kgCO2-eq"
                     step={0.000000000001}
                     size="large"
                  />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item
                  label="GWP stage C1"
                  rules={[
                     {
                        required: false,
                        message: "Please enter GWP stage C1"
                     }
                  ]}
                  name={name !== undefined ? [name, `gwp_c1`] : "gwp_c1"}>
                  <InputNumber
                     type="number"
                     placeholder="kgCO2-eq"
                     step={0.000000000001}
                     size="large"
                  />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item
                  label="GWP stage C3"
                  rules={[
                     {
                        required: false,
                        message: "Please enter GWP stage C3"
                     }
                  ]}
                  name={name !== undefined ? [name, `gwp_c3`] : "gwp_c3"}>
                  <InputNumber
                     type="number"
                     placeholder="kgCO2-eq"
                     step={0.000000000001}
                     size="large"
                  />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item
                  label="GWP stage C4"
                  rules={[
                     {
                        required: false,
                        message: "Please enter GWP stage C4"
                     }
                  ]}
                  name={name !== undefined ? [name, `gwp_c4`] : "gwp_c4"}>
                  <InputNumber
                     type="number"
                     placeholder="kgCO2-eq"
                     step={0.000000000001}
                     size="large"
                  />
               </Form.Item>
            </Col>
         </Row>


         <Row gutter={16}>
            <Col span="18">
               <Form.Item
                  label="LCA Unit"
                  rules={[
                     {
                        required: true,
                        message: "Please enter LCA Unit"
                     }
                  ]}
                  name={name !== undefined ? [name, `lca_unit`] : "lca_unit"}>
                  <Select onChange={onChangeLcaUnit}>
                     <Select.Option value="Stück" key="Stück">
                        Stück
                     </Select.Option>
                     <Select.Option value="m²" key="m²">
                        m²
                     </Select.Option>
                     <Select.Option value="m³" key="m³">
                        m³
                     </Select.Option>
                     <Select.Option value="kg" key="kg">
                        kg
                     </Select.Option>
                     <Select.Option value="tons" key="tons">
                        Tonnen
                     </Select.Option>
                     <Select.Option value="m" key="m">
                        meters
                     </Select.Option>
                  </Select>
               </Form.Item>
            </Col>
         </Row>

         {name === undefined && !!showDefaultMaterial && (
            <>
               {materials && (
                  <MaterialDropdown
                     name={['default_material']}
                     materials={materials}
                     initial={{
                        parent_material_id:
                           savedData?.default_material?.parent_material_id,
                        sub_material_id: savedData?.default_material?.sub_material_id
                     }}
                     setSubMaterial={setSubMaterial}
                     required={false}
                  />
               )}
            </>
         )}

         {subMaterial && (
            <Row gutter={16}>
               <Col span="9">
                  <Form.Item
                     label="Density min."
                     initialValue={subMaterial.min}
                     name={name !== undefined ? [name, `min`] : (!!showDefaultMaterial ? "density_min" : "min")}>
                     <InputNumber type="number" step="any"></InputNumber>
                  </Form.Item>
               </Col>
               <Col span="9">
                  <Form.Item
                     label="Density max."
                     initialValue={subMaterial.max || subMaterial.min}
                     name={name !== undefined ? [name, `max`] : (!!showDefaultMaterial ? "density_max" : "max")}>
                     <InputNumber type="number" step="any"></InputNumber>
                  </Form.Item>
               </Col>
            </Row>
         )}

         {subMaterial === undefined && !!showDefaultMaterial && name === undefined && (
            <Row gutter={16}>
               <Col span="9">
                  <Form.Item
                     label="Density min."
                     name={"density_min"}>
                     <InputNumber type="number" step="any"></InputNumber>
                  </Form.Item>
               </Col>
               <Col span="9">
                  <Form.Item
                     label="Density max."
                     name={"density_max"}>
                     <InputNumber type="number" step="any"></InputNumber>
                  </Form.Item>
               </Col>
            </Row>
         )}
      </>
   );
};
