import React from "react";
import { Drawer, Button, message } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
import { getUploadedProductImagesLinks } from "./getUploadedProductImagesLinks";
import { getSpecification } from "./getSpecification";
import { ProductForm } from "./ProductForm";

export interface productForm {
   category: object;
   title: string;
   brand: string;
   description: string;
   notes: string;
   ean: string;
}

export const AddEditProduct = ({
   showForm,
   onSubmitSuccess,
   onCancel
}: any) => {
   const [
      { data, loading: postLoading, error: postError },
      executePost
   ] = useAxios(
      {
         url: Resource.path.createProduct,
         method: "POST"
      },
      {
         manual: true,
         useCache: true
      }
   );

   const handleOnSubmit = async (values: any) => {
      if (!values) return;

      const { category, title, brand, description, notes, ean } = values;

      if (!category || !category.name) {
         message.error("Please make sure you select category");
         return;
      }

      if (!brand || !brand.name) {
         message.error("Please make sure you select brand");
         return;
      }

      const key = "addProductLoadingKey";
      message.loading({ content: "Saving...", key });

      const specification: any = getSpecification(values);
      const productImages: any[] =
         values.productImages && (await getUploadedProductImagesLinks(values));

      executePost({
         data: {
            category,
            title,
            brand_id: brand.id,
            brand_name: brand.name,
            category_id: category.id,
            category_name: category.name,
            description,
            notes,
            ean,
            specification,
            ...(productImages && productImages.length
               ? {
                    product_images: productImages
                 }
               : {})
         }
      }).then(() => {
         message.success(
            {
               content: "Product saved successfully",
               key
            },
            0
         );
         setTimeout(() => {
            message.destroy(key);
            onSubmitSuccess && onSubmitSuccess(true);
         }, 100);
      });
   };

   const handleOnCancel = () => {
      onCancel();
   };

   if (postError) {
      message.error(postError);
   }

   return (
      <Drawer
         title="Create a new Product"
         width={"80vw"}
         onClose={handleOnCancel}
         visible={showForm}
         bodyStyle={{ paddingBottom: 80 }}>
         <ProductForm onFinish={handleOnSubmit}>
            <div
               style={{
                  textAlign: "right"
               }}>
               <Button onClick={handleOnCancel} style={{ marginRight: 8 }}>
                  Cancel
               </Button>
               <Button type="primary" htmlType="submit" loading={postLoading}>
                  Save Product
               </Button>
            </div>
         </ProductForm>
      </Drawer>
   );
};
