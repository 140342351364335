import React, { useState } from "react";
import { Button } from "antd";
import "./style.scss";
import { AddEditCategoriesTree } from "../AddEditCategories/index";

const ActionHeader = ({
   onSubmitSuccess,
   initialCategoriesData,
   onCategoryEdit
}: any) => {
   const [showAddEditForm, setShowAddEditForm] = useState(false);

   const handleShowAddEditForm = () => {
      setShowAddEditForm(true);
   };
   const handleAddEditFormCancelEvent = () => {
      setShowAddEditForm(false);
      onCategoryEdit(null);
   };

   const handleAddEditFormSubmitEvent = () => {
      setShowAddEditForm(false);
      onCategoryEdit(null);
   };

   const handleOnEditSuccess = () => {
      setShowAddEditForm(false);
      onCategoryEdit(null);
      onSubmitSuccess(true);
   };

   React.useEffect(() => {
      console.log({ initialCategoriesData });

      if (initialCategoriesData) {
         setShowAddEditForm(true);
      } else {
         setShowAddEditForm(false);
      }
   }, [initialCategoriesData]);

   return (
      <>
         <div className="action-header-wrapper">
            <Button type="primary" size="large" onClick={handleShowAddEditForm}>
               Add Category Tree
            </Button>
         </div>
         {showAddEditForm && (
            <AddEditCategoriesTree
               onSubmitSuccess={onSubmitSuccess}
               showForm={showAddEditForm || initialCategoriesData}
               initialData={initialCategoriesData}
               onSubmit={handleAddEditFormSubmitEvent}
               onEditSuccess={handleOnEditSuccess}
               onCancel={handleAddEditFormCancelEvent}></AddEditCategoriesTree>
         )}
      </>
   );
};

export default ActionHeader;
