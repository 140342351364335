import React from "react";
import { Drawer, Button, Form, Col, Row, Input, message, Select } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
const { Option } = Select;

const scopes = [
   { label: "Marketplace", value: "marketplace" },
   { label: "Material Passport", value: "material-passport" },
   { label: "Material Hub", value: "material-hub" }
];

const getScopesOption = () => {
   return scopes.map((item) => {
      return (
         <Option label={item.label} value={item.value}>
            {item.label}
         </Option>
      );
   });
};

var passwordRegex = new RegExp(
   "^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})"
);

const UserForm = ({ children, onFinish, initialValues }: any) => {
   const [form] = Form.useForm();

   return (
      <Form
         layout="vertical"
         form={form}
         onFinish={onFinish}
         initialValues={initialValues}
         name="user_form"
         size="large">
         <Row gutter={16}>
            <Col span="12">
               <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                     {
                        required: true,
                        message: "Please enter email address"
                     }
                  ]}>
                  <Input placeholder="Email" size="large" type="email" />
               </Form.Item>
               <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                     {
                        required: true,
                        message: "Please select password"
                     }
                  ]}>
                  <Input placeholder="Password" size="large" type="password" />
               </Form.Item>
               <div>
                  Password must be at least 10 characters and contains at least
                  1 number, 1 allowed special character from !@#$%^&*_ and 1
                  upper case letter
               </div>
               <Form.Item
                  label="Permission"
                  name="custom:scopes"
                  rules={[
                     {
                        required: true,
                        message: "Please select permission"
                     }
                  ]}>
                  <Select
                     mode="multiple"
                     placeholder="Select permission for modules">
                     {getScopesOption()}
                  </Select>
               </Form.Item>
            </Col>
         </Row>
         {children}
      </Form>
   );
};

export const AddAppUsersForm = ({
   showForm,
   initialValues = { 'custom:scopes': ['material-hub'] },
   onSubmitSuccess,
   onCancel
}: any) => {
   const [
      { data, loading: postLoading, error: postError },
      executePost
   ] = useAxios(
      {
         url: `${Resource.path.createAsterixAppUsers}`,
         method: "POST"
      },
      {
         manual: true,
         useCache: false
      }
   );

   const handleOnSubmit = async (values: any) => {
      const key = "addUserFormLoadingKey";

      try {
         if (
            !passwordRegex.test(values.password) ||
            values.password.length < 10
         ) {
            message.error(`Password must be at least 10 characters and contains at least
                  1 number, 1 allowed special character from !@#$%^&*_ and 1
                  upper case letter`);
            return;
         }

         message.loading({ content: "Loading...", key });

         //console.log({ value: values });

         await executePost({
            data: {
               email: values.email,
               password: values.password,
               attribute: {
                  Name: "custom:scopes",
                  Value: values["custom:scopes"].join()
               }
            }
         }).then(() => {
            onSubmitSuccess(true);
            message.success(
               { content: "User form saved successfully", key },
               0
            );

            setTimeout(() => {
               message.destroy(key);
            }, 100);
         });
      } catch (error) {
         setTimeout(() => {
            message.destroy(key);
         }, 100);
         console.log(error);
      }
   };

   if (postError) {
      message.error(postError?.response?.data?.message);
   }

   const handleOnCancel = () => {
      onCancel();
   };

   return (
      <Drawer
         title="Add/Edit User Form"
         width={"60vw"}
         onClose={handleOnCancel}
         visible={showForm}
         bodyStyle={{ paddingBottom: 60 }}>
         <UserForm onFinish={handleOnSubmit} initialValues={initialValues}>
            <footer>
               <div
                  style={{
                     textAlign: "right"
                  }}>
                  <Button onClick={handleOnCancel} style={{ marginRight: 8 }}>
                     Cancel
                  </Button>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={postLoading}>
                     Save
                  </Button>
               </div>
            </footer>
         </UserForm>
      </Drawer>
   );
};
