import React from "react";
import { Form, Slider } from "antd";
import * as Joi from "joi";

export const SliderFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("slider")
   }).unknown()
});

export interface SliderField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      [key: string]: any;
   };
}

export const SliderField = (props: SliderField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   return (
      <Form.Item {...formItemProps}>
         <Slider {...fieldProps}></Slider>
      </Form.Item>
   );
};
