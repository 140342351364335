import React, { useState } from "react";
import { Button } from "antd";
import "./style.scss";
import { AddEditBrandsForm } from "../AddEditBrands";
import BrandSearchField from "../BrandSearchField";

const ActionHeader = ({ onSubmitSuccess, onFieldSelected }: any) => {
   const [showAddEditForm, setShowAddEditForm] = useState(false);
   const handleShowAddEditForm = () => {
      setShowAddEditForm(true);
   };
   const handleAddEditFormCancelEvent = () => {
      setShowAddEditForm(false);
   };

   const handleAddEditFormSubmitEvent = () => {
      setShowAddEditForm(false);
   };

   return (
      <>
         <div className="action-header-wrapper">
            <Button type="primary" size="large" onClick={handleShowAddEditForm}>
               Add Brand
            </Button>
         </div>
         {showAddEditForm && (
            <AddEditBrandsForm
               onSubmitSuccess={onSubmitSuccess}
               showForm={showAddEditForm}
               onSubmit={handleAddEditFormSubmitEvent}
               onCancel={handleAddEditFormCancelEvent}></AddEditBrandsForm>
         )}
      </>
   );
};

export default ActionHeader;
