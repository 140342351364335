import React from "react";
import { Layout } from "antd";

import "./style.css";
import { SideBarMenu } from "./SidebarMenu/SideBarMenu";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { configure } from "axios-hooks";
import { Auth } from "aws-amplify";
import axios from "axios";

const { Header, Content } = Layout;

setInterval(async () => {
   try {
      const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
      configure({
         axios: axios.create({
            timeout: 6000,
            headers: { Authorization: jwtToken }
         }),
         cache: false
      });
   } catch (error) {
      if (!document.getElementById("login_page_hidden")) {
         window.location.reload();
      }
   }
}, 10000);

export const MainLayout = ({ children }: any) => {
   return (
      <Layout>
         <SideBarMenu></SideBarMenu>
         <Layout>
            <Header
               className="site-layout-sub-header-background"
               style={{ padding: 0 }}>
               <div className="logout-button">
                  <AmplifySignOut></AmplifySignOut>
               </div>
            </Header>
            <Content style={{ margin: "24px 16px 0" }}>
               <div
                  className="site-layout-background"
                  style={{ padding: 24, minHeight: "90vh" }}>
                  {children}
               </div>
            </Content>
         </Layout>
      </Layout>
   );
};
