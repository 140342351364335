import React, { useEffect, useState } from "react";
import { Drawer, Button, Form, Col, Row, Input, message } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";

const MaterialForm = ({ children, onFinish }: any) => {
   const [form] = Form.useForm();

   return (
      <Form
         layout="vertical"
         form={form}
         onFinish={onFinish}
         name="materials_form">
         <Row gutter={16}>
            <Col span="8">
               <Form.Item
                  label="Material Group Name (German)"
                  name="de_group_name"
                  rules={[
                     {
                        required: true,
                        message: "Please enter material group name"
                     }
                  ]}>
                  <Input placeholder="Material Group Name" size="large" />
               </Form.Item>
            </Col>
            <Col span="8">
               <Form.Item
                  label="Material  Name (German)"
                  name="de_material_name"
                  rules={[
                     {
                        required: true,
                        message: "Please enter material name"
                     }
                  ]}>
                  <Input placeholder="Material Name" size="large" />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="8">
               <Form.Item
                  label="Material Group Name (English)"
                  name="en_group_name"
                  rules={[
                     {
                        required: true,
                        message: "Please enter material group name"
                     }
                  ]}>
                  <Input placeholder="Material Group Name" size="large" />
               </Form.Item>
            </Col>
            <Col span="8">
               <Form.Item
                  label="Material  Name (English)"
                  name="en_material_name"
                  rules={[
                     {
                        required: true,
                        message: "Please enter material name"
                     }
                  ]}>
                  <Input placeholder="Material Name" size="large" />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="8">
               <Form.Item label="Max" name="max">
                  <Input placeholder="Max" size="large" />
               </Form.Item>
            </Col>
            <Col span="8">
               <Form.Item label="Min" name="min">
                  <Input placeholder="Min" size="large" />
               </Form.Item>
            </Col>
         </Row>
         {children}
      </Form>
   );
};

export const AddEditMaterialsForm = ({
   showForm,
   onSubmitSuccess,
   onCancel
}: any) => {
   const [
      { data, loading: postLoading, error: postError },
      executePost
   ] = useAxios(
      {
         url: Resource.path.createMaterials,
         method: "POST"
      },
      {
         manual: true,
         useCache: true
      }
   );

   const handleOnSubmit = async (values: any) => {
      const key = "addMaterialLoadingKey";
      message.loading({ content: "Loading...", key });

      executePost({
         data: {
            ...values
         }
      }).then(() => {
         message.success(
            {
               content: "Material saved successfully",
               key
            },
            0
         );

         setTimeout(() => {
            message.destroy(key);
            onSubmitSuccess(true);
         }, 100);
      });
   };
   const handleOnCancel = () => {
      onCancel();
   };

   return (
      <Drawer
         title="Create a new Material"
         width={"60vw"}
         onClose={handleOnCancel}
         visible={showForm}
         bodyStyle={{ paddingBottom: 60 }}>
         <MaterialForm onFinish={handleOnSubmit}>
            <footer>
               <div
                  style={{
                     textAlign: "right"
                  }}>
                  <Button onClick={handleOnCancel} style={{ marginRight: 8 }}>
                     Cancel
                  </Button>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={postLoading}>
                     Save Material
                  </Button>
               </div>
            </footer>
         </MaterialForm>
      </Drawer>
   );
};
