import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Row, Col, Space, Button, message } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../../api/common";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./style.css";
import { PlusOutlined } from "@ant-design/icons";
import { FormFieldSearchSelect } from "./FormFieldSearchSelect";
import { getSaveFormTemplateHandler } from "./getSaveFormTemplateHandler";
import { FormRenderer } from "./FormRenderer";
import { defaultTemplateSchema, layout } from "./defaultTemplateSchema";
import * as uuid from "uuid";
import ProductGroupSearchField from "../../../ProductGroups/ProductGroupSearchField";

export const AddEditTemplateViews = () => {
   const [{ data, loading, error }] = useAxios(Resource.path.getFormFields);
   const [productGroupId, setProductGroupId] = useState<any>("");
   const [form] = Form.useForm();
   const history = useHistory();
   const location = useLocation();
   const [templateSchema, setTemplateSchema] = useState<any>(
      defaultTemplateSchema || []
   );
   const [selectedField, setSelectedField] = useState<any>();
   const [selectedProductGroupId, setSelectedProductGroupId] = useState<any>();
   const [selectedFormTemplate, setSelectedFormTemplate] = useState<any>();

   const isEditMode = !!location?.state;

   const [
      { data: formTemplateResponse, loading: postLoading, error: postError },
      executePost
   ] = useAxios(
      {
         url: Resource.path.createFormTemplates,
         method: "POST"
      },
      {
         manual: true,
         useCache: false
      }
   );

   const [
      { data: updateData, loading: putLoading, error: putError },
      executePut
   ] = useAxios(
      {
         url: Resource.path.updateFormTemplate,
         method: "PUT",
         headers: { "Content-Type": "*" }
      },
      {
         manual: true,
         useCache: false
      }
   );

   useEffect(() => {
      if (location?.state) {
         const { field }: any = location?.state;
         if (field) {
            setSelectedFormTemplate(field);
            form.setFieldsValue({ name: field.name });
            form.setFieldsValue({ description: field.description });
            setTemplateSchema(field.template);
            setSelectedProductGroupId(field.product_group_id);
            setProductGroupId(field.product_group_id);
         }
      }
   }, [location]);

   const onFieldSelect = useCallback(
      (fieldTemplate: any) => {
         const parsedJsonFormTemplate = JSON.parse(fieldTemplate);
         setSelectedField(parsedJsonFormTemplate);
      },
      [selectedField]
   );

   const handleRemoveFormElement = useCallback(
      (item: any) => {
         const templates = templateSchema.filter(
            (template: any) => item.id !== template.id
         );

         setTemplateSchema(() => [...templates]);
      },
      [templateSchema]
   );

   const formFields = data?.data?.data || [];

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page</p>;

   const onProductGroupSelected = (value: any) => {
      const { id: productGroupId } = value;
      setProductGroupId(productGroupId);
      setSelectedProductGroupId(productGroupId);
   };

   const handleSaveFormTemplate = getSaveFormTemplateHandler({
      form,
      executePost,
      executePut,
      productGroupId,
      templateSchema,
      history,
      isEditMode,
      formTemplateId: selectedFormTemplate?.id
   });

   if (postError) {
      message.error(postError);
   }

   const onItemSorted = (templateSchema: any) => {
      setTemplateSchema(templateSchema);
   };

   const onAddField = () => {
      if (selectedField) {
         setTemplateSchema((templateSchema: any) => [
            ...templateSchema,
            {
               id: uuid.v4(),
               ...{
                  ...selectedField,
                  formItem: {
                     ...selectedField.formItem,
                     name: `${
                        selectedField.formItem.name
                     }_::specification-field::_${uuid.v4()}`
                  }
               }
            }
         ]);
      } else {
         message.info("Please select a field");
      }
   };

   return (
      <>
         <Form
            {...layout}
            form={form}
            name="formTemplate"
            onFinish={handleSaveFormTemplate}
            requiredMark>
            <Form.Item
               label="Template Name"
               name="name"
               rules={[
                  {
                     required: true,
                     message: "Please input Form template name"
                  }
               ]}>
               <Input placeholder="Form Template" size="large"></Input>
            </Form.Item>
            <Form.Item label="Description" name="description">
               <Input.TextArea />
            </Form.Item>
            <Form.Item label="Product Group" name="product_group">
               <ProductGroupSearchField
                  disabled={isEditMode}
                  defaultSelectedProductGroupId={selectedProductGroupId}
                  onFieldSelect={
                     onProductGroupSelected
                  }></ProductGroupSearchField>
            </Form.Item>

            <div>
               <label>Form Template Preview</label>
               <Row className="form-renderer-wrapper">
                  <Col span={16} offset={4}>
                     <FormRenderer
                        onItemSorted={onItemSorted}
                        schema={templateSchema}
                        onItemRemoved={handleRemoveFormElement}></FormRenderer>
                  </Col>
               </Row>
            </div>
            <div className="form-field-search-select-wrapper">
               <Space>
                  <FormFieldSearchSelect
                     fields={formFields}
                     onFieldSelect={onFieldSelect}
                     className="search-field"></FormFieldSearchSelect>
                  <Button
                     type="primary"
                     shape="circle"
                     onClick={onAddField}
                     icon={<PlusOutlined />}
                  />
               </Space>
            </div>

            <Form.Item>
               <Row style={{ marginTop: "100px" }}>
                  <Col span={12} offset={12}>
                     <Space>
                        <Button danger size="large" style={{ width: "200px" }}>
                           <Link to="/forms/templates">Cancel</Link>
                        </Button>
                        <Button
                           type="primary"
                           size="large"
                           style={{ width: "200px" }}
                           htmlType="submit">
                           Save Form Template
                        </Button>
                     </Space>
                  </Col>
               </Row>
            </Form.Item>
         </Form>
      </>
   );
};
