import React from "react";
import { Drawer, Button, Form, Col, Row, Input, message, Select } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
const { Option } = Select;

const scopes = [
   { label: "Marketplace", value: "marketplace" },
   { label: "Material Passport", value: "material-passport" },
   { label: "Material Hub", value: "material-hub" }
];

const getScopesOption = () => {
   return scopes.map((item) => {
      return (
         <Option label={item.label} value={item.value}>
            {item.label}
         </Option>
      );
   });
};

const UserForm = ({ children, onFinish, initialValues }: any) => {
   const [form] = Form.useForm();

   console.log({ initialValues });

   initialValues = Object.assign(
      {},
      {
         ...initialValues,
         "custom:scopes": initialValues["custom:scopes"]?.split(","),
         "custom:user_type": initialValues["custom:user_type"] ? initialValues["custom:user_type"] : null
      }
   );

   return (
      <Form
         layout="vertical"
         form={form}
         onFinish={onFinish}
         initialValues={initialValues}
         name="user_form"
         size="large">
         <Row gutter={16}>
            <Col span="12">
               <Form.Item label="Email" name="email">
                  <Input placeholder="Email" size="large" disabled />
               </Form.Item>
               <Form.Item label="Permission" name="custom:scopes">
                  <Select
                     mode="multiple"
                     placeholder="Select permission for modules">
                     {getScopesOption()}
                  </Select>
               </Form.Item>
               <Form.Item label="User Segment" name="custom:user_type">
                  <Select style={{ width: 320 }} disabled={ initialValues["custom:user_type"] }>
                     <Select.Option value="private-owner">
                        Private owners
                     </Select.Option>
                     <Select.Option value="business_owners">
                        Business owners
                     </Select.Option>
                     <Select.Option value="professional">
                        Professional
                     </Select.Option>
                     <Select.Option value="reseller">
                        Reseller
                     </Select.Option>
                  </Select>
               </Form.Item>
            </Col>
         </Row>
         {children}
      </Form>
   );
};

export const AddEditAppUsersForm = ({
   showForm,
   initialValues = {},
   onSubmitSuccess,
   onCancel
}: any) => {
   const [
      { data, loading: postLoading, error: postError },
      executePut
   ] = useAxios(
      {
         url: `${Resource.path.updateAsterixAppUsers}/${initialValues.Username}`,
         method: "PUT"
      },
      {
         manual: true,
         useCache: false
      }
   );
   // console.log('initialValues', initialValues);
   const handleOnSubmit = async (values: any) => {
      try {
         const key = "addUserFormLoadingKey";
         message.loading({ content: "Loading...", key });

         console.log({ value: values });
         const attributes = [
            {
               Name: "custom:scopes",
               Value: values["custom:scopes"].join()
            }
         ];
         if(values["custom:user_type"]){
            attributes.push({
               Name: "custom:user_type",
               Value: values["custom:user_type"]
            });
         }
         if(initialValues["given_name"] == undefined){
            attributes.push({
               Name: "given_name",
               Value: ' '
            });
         }
         if(initialValues["family_name"] == undefined){
            attributes.push({
               Name: "family_name",
               Value: ' '
            });
         }
         await executePut({
            data: {
               attributes
            }
         });
         onSubmitSuccess(true);
         message.success({ content: "User form saved successfully", key }, 0);

         setTimeout(() => {
            message.destroy(key);
            
         }, 100);
      } catch (error) {
         message.error("Some error occurred");
         console.log(error);
      }
   };

   const handleOnCancel = () => {
      onCancel();
   };

   return (
      <Drawer
         title="Add/Edit User Form"
         width={"60vw"}
         onClose={handleOnCancel}
         visible={showForm}
         bodyStyle={{ paddingBottom: 60 }}>
         <UserForm onFinish={handleOnSubmit} initialValues={initialValues}>
            <footer>
               <div
                  style={{
                     textAlign: "right"
                  }}>
                  <Button onClick={handleOnCancel} style={{ marginRight: 8 }}>
                     Cancel
                  </Button>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={postLoading}>
                     Save
                  </Button>
               </div>
            </footer>
         </UserForm>
      </Drawer>
   );
};
