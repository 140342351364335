import React, { useEffect, useState } from "react";
import { Drawer, Button, Form, Col, Row, Input, message, Select } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";

const AddEditCategoriesTreeForm = ({
   children,
   onFinish,
   initialValue
}: any) => {
   const [form] = Form.useForm();

   const handleChangeLanguage = (value: string) => {
      form.setFieldsValue({ language: value });
   };

   const handleUserSegment = (value: string) => {
      form.setFieldsValue({ user_segment: value });
   };

   return (
      <Form
         layout="vertical"
         onFinish={onFinish}
         name="categories_form"
         form={form}
         initialValues={initialValue}>
         <Row gutter={16}>
            <Col span="12">
               <Form.Item
                  label="Category Tree Name"
                  name="name"
                  rules={[
                     { required: true, message: "Please enter category name" }
                  ]}>
                  <Input placeholder="Category Name" size="large" />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span="18">
               <Form.Item label="Language" name="language">
                  <Select
                     defaultValue="de"
                     style={{ width: 120 }}
                     onChange={handleChangeLanguage}>
                     <Select.Option value="de">DE</Select.Option>
                     <Select.Option value="en">EN</Select.Option>
                  </Select>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item label="User Segment" name="user_segment">
                  <Select
                     style={{ width: 320 }}
                     onChange={handleUserSegment}>
                     <Select.Option value="private-owner">
                        Private owners
                     </Select.Option>
                     <Select.Option value="business_owners">
                        Business owners
                     </Select.Option>
                     <Select.Option value="professional">
                        Professional
                     </Select.Option>
                     <Select.Option value="reseller">
                        Reseller
                     </Select.Option>
                  </Select>
               </Form.Item>
            </Col>
         </Row>

         {children}
      </Form>
   );
};

export const AddEditCategoriesTree = ({
   showForm,
   onSubmitSuccess,
   onCancel,
   initialData,
   onEditSuccess
}: any) => {
   const [isEditMode, setEditMode] = useState(false);

   const [{ data, loading: postLoading, error: postError }, executePost] =
      useAxios(
         {
            url: Resource.path.createCategoriesTree,
            method: "POST"
         },
         {
            manual: true,
            useCache: true
         }
      );

   const [{ data: putData, loading: putLoading, error: putError }, executePut] =
      useAxios(
         {
            url: Resource.path.updateCategoriesTree,
            method: "PUT"
         },
         {
            manual: true,
            useCache: true
         }
      );

   useEffect(() => {
      if (initialData && initialData?.id) {
         setEditMode(true);
      }
   }, [initialData]);

   const handleOnSubmit = async (values: any) => {
      console.log({ values });

      const key = "addCategoriesLoadingKey";
      message.loading({ content: "Loading...", key });

      if (isEditMode) {
         executePut({
            data: {
               ...initialData,
               ...values,
               language: values?.language || "de",
               user_segment: values?.user_segment || "private-owner"
            }
         }).then(() => {
            message.success(
               {
                  content: "Categories Tree edited successfully",
                  key
               },
               0
            );
            setTimeout(() => {
               message.destroy(key);
               onEditSuccess(true);
            }, 100);
         });
      } else {
         executePost({
            data: {
               ...values,
               language: values?.language || "de",
               user_segment: values?.user_segment || "private-owner"
            }
         }).then(() => {
            message.success(
               {
                  content: "Categories Tree saved successfully",
                  key
               },
               0
            );

            setTimeout(() => {
               message.destroy(key);
               onSubmitSuccess(true);
            }, 100);
         });
      }
   };
   const handleOnCancel = () => {
      onCancel();
   };

   return (
      <Drawer
         title={`${
            isEditMode ? "Edit Category" : "Create a new Categories Tree"
         }`}
         width={"60vw"}
         onClose={handleOnCancel}
         visible={showForm}
         bodyStyle={{ paddingBottom: 80 }}>
         <AddEditCategoriesTreeForm
            onFinish={handleOnSubmit}
            initialValue={initialData}>
            <footer>
               <div
                  style={{
                     textAlign: "right"
                  }}>
                  <Button onClick={handleOnCancel} style={{ marginRight: 8 }}>
                     Cancel
                  </Button>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={postLoading}>
                     Save Categories Tree
                  </Button>
               </div>
            </footer>
         </AddEditCategoriesTreeForm>
      </Drawer>
   );
};
