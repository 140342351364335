import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { SelectProps } from "antd/es/select";
import { Resource } from "../../../api/common";
import useAxios from "axios-hooks";

const buildOptionsElm = (results: any) => {
   return results.map((result: any) => {
      return (
         <Select.Option key={result.id} value={result.id}>
            {result.name}
         </Select.Option>
      );
   });
};

const ProductGroupMultipleSelectField: React.FC<any> = ({
   onFieldSelect = () => {},
   value
}) => {
   const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
   const [selectedItems, setSelectedItems] = useState<any>([]);

   useEffect(() => {
      if (value && Array.isArray(value)) {
         setSelectedItems(value);
      }
   }, [value]);

   const [{ data, loading, error }, refetch] = useAxios(
      { url: Resource.path.getProductGroups },
      { useCache: false }
   );

   useEffect(() => {
      if (data?.data.data) {
         const options = buildOptionsElm(data?.data.data);
         setOptions(options);
      }
   }, [data]);

   const onValueChange = (value: any) => {
      setSelectedItems(value);
      onFieldSelect(value);
      console.log(value);
   };

   return (
      <Select
         labelInValue={true}
         size="large"
         mode="multiple"
         placeholder="Select Product Groups"
         value={selectedItems}
         onChange={onValueChange}
         style={{ width: "100%" }}>
         {options}
      </Select>
   );
};

export default ProductGroupMultipleSelectField;
