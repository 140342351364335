import React, { useCallback, useEffect, useState } from "react";
import { TreeSelect } from "antd";
import { Resource } from "../../../api/common";
import useAxios from "axios-hooks";

const CategoriesTreeSearchField: React.FC<any> = ({ onFieldSelect }) => {
   const [options, setOptions] = useState<any>(undefined);
   const [searchTerm, setSearchTerm] = useState<any>("");

   const [{ data, loading, error }, refetch] = useAxios(
      { url: Resource.path.getCategories },
      { useCache: false }
   );

   useEffect(() => {
      if (data?.data.treeData) {
         setOptions(data?.data.treeData);
      }
   }, [data]);

   const onSelect = useCallback((value: any) => {
      const [id, categoryName] = value.split("_");
      setSearchTerm(categoryName);
      onFieldSelect && onFieldSelect({ id, name: categoryName });
   }, []);

   const onValueChange = (item: any) => {
      setSearchTerm(item);
      onFieldSelect && onFieldSelect({ id: item.value, name: item.label });
   };

   return (
      <TreeSelect
         labelInValue
         showSearch
         style={{ width: "100%" }}
         size="large"
         treeData={options}
         treeNodeFilterProp="title"
         placeholder="Please select category"
         onChange={onValueChange}
      />
   );
};

export default CategoriesTreeSearchField;
