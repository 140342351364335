import { Tree, Spin, message } from "antd";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { Resource } from "../../../api/common";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

export enum FilterType {
   "ProductGroup",
   "Category"
}

function traverse(obj: any) {
   if (obj !== null && typeof obj == "object" && obj.children) {
      obj.children.forEach((item: any) => {
         traverse(item);
      });
   } else {
      obj.isLeaf = true;
   }
}

export const CategoriesTable = ({ onDeleteItem, onCategoryEdit }: any) => {
   const [categories, setCategoriesTreeData] = useState([]);
   const [showLoader, setShowLoader] = useState<boolean>(true);
   const { id }: any = useParams();

   const [{ data: putData, loading: putLoading, error: putError }, executePut] =
      useAxios(
         {
            url: Resource.path.updateCategories,
            method: "PUT"
         },
         {
            manual: true,
            useCache: true
         }
      );

   const [{ data, loading: loadingCategories, error: getError }, executeGet] =
      useAxios(
         {
            url: `${Resource.path.getCategoriesByTreeId}/${id}`,
            method: "GET"
         },
         { useCache: false }
      );

   useEffect(() => {
      if (data?.data?.treeData) {
         const treeData = data?.data?.treeData;

         treeData.forEach((item: any) => {
            item && traverse(item);
         });
         setCategoriesTreeData(treeData);

         setShowLoader(false);
      }
   }, [data?.data?.treeData]);

   if (loadingCategories && showLoader)
      return <div className="loading">Loading....</div>;

   const handleOnDelete = (item: any) => {
      onDeleteItem(item?.id);
   };

   const handleOnEdit = (item: any) => {
      onCategoryEdit(item);
   };

   const onDrop = (info: any) => {
      if (info.dragNode?.parent_id === info?.node?.id) {
         return;
      }
      const key = "addCategoriesLoadingKey";
      message.loading({ content: "Loading...", key });

      const draggingNode = Object.assign({}, info.dragNode);
      Reflect.deleteProperty(draggingNode, "active");
      Reflect.deleteProperty(draggingNode, "checked");
      Reflect.deleteProperty(draggingNode, "dragOver");
      Reflect.deleteProperty(draggingNode, "dragOverGapBottom");
      Reflect.deleteProperty(draggingNode, "dragOverGapTop");
      Reflect.deleteProperty(draggingNode, "expanded");
      Reflect.deleteProperty(draggingNode, "halfChecked");
      Reflect.deleteProperty(draggingNode, "loaded");
      Reflect.deleteProperty(draggingNode, "loading");
      Reflect.deleteProperty(draggingNode, "pos");
      Reflect.deleteProperty(draggingNode, "selected");
      Reflect.deleteProperty(draggingNode, "updated_at");

      console.log({ info });
      executePut({
         data: {
            ...draggingNode,
            parent_id: info?.node?.id,
            parent_category_name: info?.node?.name
         }
      }).then(() => {
         message.success(
            {
               content: "Categories edited successfully",
               key
            },
            0
         );

         setTimeout(() => {
            message.destroy(key);
            executeGet({
               url: `${Resource.path.getCategoriesByTreeId}/${id}`,
               method: "GET"
            });
         }, 100);
      });
   };

   return (
      <Spin tip="Loading..." spinning={loadingCategories}>
         {categories && categories.length && (
            <Tree
               showLine={true}
               showIcon={true}
               treeData={categories}
               defaultExpandAll
               draggable
               onDrop={onDrop}
               titleRender={(item: any) => {
                  return (
                     <div
                        style={{
                           width: "500px",
                           display: "flex",
                           justifyContent: "space-between"
                        }}>
                        <div> {item.title}</div>
                        <ul style={{ marginTop: "25px" }}>
                           {item?.product_groups?.map((group: any) => {
                              return <li key={group?.value}>{group?.label}</li>;
                           })}
                        </ul>

                        <div>
                           <FormOutlined
                              onClick={() => handleOnEdit(item)}
                              style={{ marginRight: "30px" }}
                           />
                           <DeleteOutlined
                              onClick={() => handleOnDelete(item)}
                           />
                        </div>
                     </div>
                  );
               }}
            />
         )}
      </Spin>
   );
};
