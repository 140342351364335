import React from "react";
import { Button } from "antd";
import "./style.scss";
import { Link } from "react-router-dom";

const NavigationHeader = () => {
  return (
    <div className="action-header-wrapper">
      <Button type="primary" size="large">
        <Link to="/forms/fields/add">Add Field</Link>
      </Button>
    </div>
  );
};

export default NavigationHeader;
