import React, { useState } from "react";
import { ProductGroupTable } from "./ProductGroupTable";
import ActionHeader from "./ActionHeader/ActionHeader";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { message } from "antd";
import { Mapping } from "./Mapping";

const limitCount = 20;

export const ProductGroupViews = () => {
   const [params, setParams] = useState<any>({
      cursor: 0,
      limit: limitCount
   })
   const [searchTerm, setSearchTerm] = React.useState<any>();
   const [{ data, loading, error }, refetch] = useAxios({
      url: Resource.path.getProductGroups,
      params
   });
   const [productGroup, setProductGroup] = React.useState(null);
   const [showMappingDrawer, setShowMappingDrawer] = React.useState(false);
   const [selectedItem, setSelectedItem] = React.useState({});
   const [
      { data: deleteData, loading: deleteLoading, error: deleteError },
      executeDelete
   ] = useAxios(
      {
         method: "delete"
      },
      { manual: true }
   );

   if (loading && searchTerm == undefined) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page</p>;

   const handleOnSubmitSuccess = () => {
      refetch();
   };

   const onDeleteProductGroup = (productGroupId: string) => {
      executeDelete({
         url: `${Resource.path.removeProductGroups}/${productGroupId}`
      }).then(() => {
         refetch();
         message.success("Product Group deleted successfully.");
      }).catch((error)=>{
         console.log(error, error.response);
         message.error(error?.response?.data?.message || `Something went wrong, please try again`);
      });
   };

   const onProductGroupEdit = (record: any) => {
      setProductGroup(record);
   };

   const onSearch = async (val: string) => {
      if (val) {
         setParams({
            cursor: 0,
            limit: limitCount,
            search: val
         });
         setSearchTerm(val)
      } else {
         setParams({
            cursor: 0,
            limit: limitCount
         });
         setSearchTerm(null)
      }
      await refetch();
   }
   

   return (
      <>
         <ActionHeader
            onProductGroupEdit={onProductGroupEdit}
            onSubmitSuccess={handleOnSubmitSuccess}
            onSearch={onSearch}
            initialProductGroupData={productGroup}
            productGroups={data.data.data}
            ></ActionHeader>
         <ProductGroupTable
            data={data.data.data}
            onDeleteProductGroup={onDeleteProductGroup}
            onProductGroupEdit={onProductGroupEdit}
            loading={loading}
            showMapping={(value:any)=>{
               setSelectedItem(value);
               setShowMappingDrawer(true);
            }}
            searchTerm={searchTerm}
            setParams={setParams}
            pagination={{
               total: data.data.total,
               current: params.cursor + 1,
               pageSize: limitCount
            }}
         />
         {showMappingDrawer && <Mapping visible={showMappingDrawer} selected={selectedItem} onClose={() => { setShowMappingDrawer(!showMappingDrawer); }} />}
      </>
   );
};
