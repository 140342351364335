import React from "react";
import { Form, Radio } from "antd";
import * as Joi from "joi";

export const RadioFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("radio"),
      options: Joi.array().required()
   }).unknown()
});

export interface RadioField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      [key: string]: any;
   };
}

const getOptionsElement = (options: any) => {
   return options.map((option: any) => {
      return (
         <Radio key={option} value={option}>
            {option}
         </Radio>
      );
   });
};

export const RadioField = (props: RadioField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   const optionsElm = getOptionsElement(fieldProps.options);
   const radioFieldProps = Object.assign({}, { ...fieldProps });

   // Remove unsupported properties so that generic Select work as expected
   Reflect.deleteProperty(radioFieldProps, "options");

   return (
      <Form.Item {...formItemProps}>
         <Radio.Group {...radioFieldProps}>{optionsElm}</Radio.Group>
      </Form.Item>
   );
};
