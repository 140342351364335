import { Space, Table, Popconfirm, Anchor } from "antd";
import React from "react";
import { DeleteTwoTone, EditFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const CategoriesTreeTable = ({
   data,
   onDeleteItem,
   onCategoryEdit
}: any) => {
   const columns: any = [
      {
         title: "Name",
         width: 250,
         fixed: "left",
         render: (record: any) => {
            return <Link to={`/categories/${record?.id}`}>{record?.name}</Link>;
         }
      },
      {
         title: "User Segments",
         dataIndex: "user_segment",
         key: "user_segment",
         width: 350
      },
      {
         title: "Language",
         dataIndex: "language",
         key: "language",
         width: 300
      },

      {
         title: "Action",
         key: "action",
         width: 90,
         fixed: "right",

         render: (record: any) => (
            <Space size="middle">
               <Popconfirm
                  title="Are you sure to delete this Category ?"
                  onConfirm={() => onConfirm(record.id)}
                  okText="Yes"
                  cancelText="No">
                  <DeleteTwoTone />
               </Popconfirm>
               <EditFilled onClick={() => onItemSelectedForEdit(record)} />
            </Space>
         )
      }
   ];

   const onConfirm = (categoryId: string) => {
      onDeleteItem(categoryId);
   };

   const onItemSelectedForEdit = (record: any) => {
      onCategoryEdit(record);
   };

   return (
      <Table
         pagination={false}
         dataSource={data}
         columns={columns}
         scroll={{ x: 1300 }}
         rowKey={(record) => record.id}></Table>
   );
};
