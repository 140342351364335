import { Space, Table, Tag, Image, Button, message } from "antd";
import React, { useState } from "react";
import { EditTwoTone, DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";
import { AddEditAppUsersForm } from "../EditAppUsers/index";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";

const getUserAttributes = ({ Attributes = [] }: any) => {
   const userAttributes: any = {};
   Attributes.forEach((attribute: any) => {
      return (userAttributes[attribute.Name] = attribute.Value);
   });

   return userAttributes;
};

export const UsersTable = ({ users, refetch }: any) => {
   const [showAddEditForm, setShowAddEditForm] = useState(false);
   const [formInitialValues, setFormInitialValues] = useState({});
   const [{ data, loading: postLoading, error: postError }, executePost] =
      useAxios(
         {
            url: `${Resource.path.resetAsterixAppUsersPassword}`,
            method: "POST"
         },
         {
            manual: true,
            useCache: false
         }
      );
   const handleAddEditFormCancelEvent = () => {
      setShowAddEditForm(false);
   };

   const handleAddEditFormSubmitEvent = () => {
      setShowAddEditForm(false);
   };

   const columns: any = [
      {
         title: "Username",
         dataIndex: "Username",
         key: "Username",
         fixed: "left"
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
         fixed: "left"
      },
      {
         title: "Email Verified",
         dataIndex: "email_verified",
         key: "email_verified",
         fixed: "left"
      },
      {
         title: "Enabled",
         dataIndex: "Enabled",
         key: "Enabled"
      },
      {
         title: "User Create Date",
         dataIndex: "UserCreateDate",
         key: "UserCreateDate"
      },
      {
         title: "User Last Modified Date",
         dataIndex: "UserLastModifiedDate",
         key: "UserLastModifiedDate"
      },
      {
         title: "Action",
         key: "action",
         width: 250,
         fixed: "right",

         render: (user: any) => {
            return (
               <Space size="middle">
                  <EditTwoTone onClick={() => onUserEdit(user)} />

                  {user?.UserStatus === "FORCE_CHANGE_PASSWORD" && (
                     <Button type="primary" onClick={() => resetPassword(user)}>
                        Resend Temporary Password
                     </Button>
                  )}
               </Space>
            );
         }
      }
   ];

   const onUserEdit = (user: any) => {
      setShowAddEditForm(true);
      console.log({ user });
      setFormInitialValues(user);
   };

   const resetPassword = async (user: any) => {
      console.log({ user });

      if (postLoading) return;

      console.log({ user });
      await executePost({
         data: {
            user: user
         }
      });
      message.success("Temporary password has been sent successfully");
   };

   const formattedData = users.map((user: any) => {
      return {
         Username: user.Username,
         UserStatus: user.UserStatus,
         Enabled: String(user.Enabled),
         UserCreateDate: user.UserCreateDate,
         UserLastModifiedDate: user.UserLastModifiedDate,
         ...getUserAttributes(user)
      };
   });

   return (
      <>
         <Table
            pagination={false}
            dataSource={formattedData}
            columns={columns}
            scroll={{ x: 1300 }}
            rowKey={(record) => record.id}></Table>
         {showAddEditForm && (
            <AddEditAppUsersForm
               onSubmitSuccess={refetch}
               showForm={showAddEditForm}
               initialValues={formInitialValues}
               onSubmit={handleAddEditFormSubmitEvent}
               onCancel={handleAddEditFormCancelEvent}></AddEditAppUsersForm>
         )}
      </>
   );
};
