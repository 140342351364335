import React from "react";
import ActionHeader from "./ActionHeader/ActionHeader";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { BrandsTable } from "./BrandsTable";

export const BrandsViews = () => {
   const [{ data, loading, error }, refetch] = useAxios(
      Resource.path.getBrands
   );

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page Please reload page</p>;
   const handleOnSubmitSuccess = (value: any) => {
      refetch();
   };
   return (
      <>
         <ActionHeader onSubmitSuccess={handleOnSubmitSuccess}></ActionHeader>
         <BrandsTable data={data.data.data} loading={loading} />
      </>
   );
};
