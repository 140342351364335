import React, { useCallback, useEffect, useState } from "react";
import { Input, AutoComplete } from "antd";
import { SelectProps } from "antd/es/select";
import { Resource } from "../../../api/common";
import useAxios from "axios-hooks";

const buildOptionsElm = (results: any) => {
   return results.map((result: any) => {
      return {
         value: `${result.id}_${result.name}`,
         label: result.name
      };
   });
};

const ProductGroupSearchField: React.FC<any> = ({
   defaultSelectedProductGroupId,
   onFieldSelect,
   disabled = false
}) => {
   const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
   const [searchTerm, setSearchTerm] = useState<any>("");

   const [{ data, loading, error }, refetch] = useAxios(
      { url: Resource.path.getProductGroups },
      { useCache: false }
   );

   useEffect(() => {
      if (data?.data.data) {
         const options = buildOptionsElm(data?.data.data);
         setOptions(options);

         if (options) {
            const matchedOption = data?.data.data.find((option: any) => {
               return option.id === defaultSelectedProductGroupId;
            });

            matchedOption?.name && setSearchTerm(matchedOption?.name || "");
         }
      }
   }, [data]);

   const handleSearch = (value: string) => {
      const productGroups = data?.data?.data;
      const filteredResults = productGroups.filter((item: any) => {
         return item?.name?.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      setOptions(buildOptionsElm(filteredResults));
   };

   const onSelect = useCallback((value: any) => {
      const [id, productGroupName] = value.split("_");
      setSearchTerm(productGroupName);
      onFieldSelect && onFieldSelect({ id, name: productGroupName });
   }, []);

   const onValueChange = (value: any) => {
      setSearchTerm(value);
   };

   return (
      <AutoComplete
         disabled={disabled}
         value={searchTerm}
         options={options}
         onChange={onValueChange}
         onSelect={onSelect}
         onSearch={handleSearch}>
         <Input.Search
            size="large"
            placeholder="Search product group by name"
            enterButton
         />
      </AutoComplete>
   );
};

export default ProductGroupSearchField;
