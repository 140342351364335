import React from "react";
import { FieldsViews } from "./FieldsViews";
import { TemplateView } from "./TemplateViews";
import { Switch, Route } from "react-router-dom";

export const FormsViews = () => {
  return (
    <Switch>
      <Route path="/forms/fields" component={FieldsViews}></Route>
      <Route path="/forms/templates" component={TemplateView}></Route>
    </Switch>
  );
};
