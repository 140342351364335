import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { FormElementFieldMap } from "../../../../components/FormFields/FormElementFieldMap";
import { layout } from "./defaultTemplateSchema";
import { MinusOutlined, DragOutlined } from "@ant-design/icons";
import {
   sortableContainer,
   sortableElement,
   sortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";

const DragHandle = sortableHandle(() => (
   <span className="field-dragger">
      <DragOutlined />
   </span>
));

const SortableItem = sortableElement(({ children }: any) => (
   <div style={{ position: "relative" }}>
      {children}
      <DragHandle />
   </div>
));

const SortableContainer = sortableContainer(({ children }: any) => {
   return <div>{children}</div>;
});

export const FormRenderer = React.memo(
   ({ schema, onItemRemoved, onItemSorted }: any) => {
      const onRemoveItem = (item: any) => {
         onItemRemoved(item);
      };
      const [schemas, setSchemas] = useState<any>([]);

      useEffect(() => {
         if (schema && Array.isArray(schema) && schema.length) {
            setSchemas(schema);
         } else {
            setSchemas([]);
         }
      }, [schema]);

      const elements = schemas.map((item: any, index: number) => {
         const TagName = FormElementFieldMap.get(item.field.type);
         return (
            <SortableItem key={item.formItem.name} index={index}>
               <div className="form-elements-wrapper">
                  <TagName key={item.formItem.name} {...item}></TagName>
                  <Button
                     type="default"
                     shape="circle"
                     icon={<MinusOutlined />}
                     className="remove-button"
                     onClick={() => {
                        onRemoveItem(item);
                     }}
                  />
               </div>
            </SortableItem>
         );
      });

      const onSortEnd = ({ oldIndex, newIndex }: any) => {
         const orderedSchema = arrayMove(schemas, oldIndex, newIndex);
         setSchemas(orderedSchema);
         onItemSorted(orderedSchema);
      };

      return (
         <Form {...layout}>
            <SortableContainer onSortEnd={onSortEnd} useDragHandle>
               {elements}
            </SortableContainer>
         </Form>
      );
   }
);
