import React from "react";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { message } from "antd";
import { CategoriesTreeTable } from "./CategoriesTreeTable/index";
import ActionHeader from "./ActionHeader/ActionHeader";

export const CategoriesTreeViews = () => {
   const [{ data, loading, error }, refetch] = useAxios(
      Resource.path.getCategoriesTree
   );

   const [category, setCategory] = React.useState(null);

   const [
      { data: deleteData, loading: deleteLoading, error: deleteError },
      executeDelete
   ] = useAxios(
      {
         method: "delete"
      },
      { manual: true }
   );

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page</p>;

   const handleOnSubmitSuccess = () => {
      refetch();
   };

   const onDeleteItem = (categoryTreeId: string) => {
      executeDelete({
         url: `${Resource.path.deleteCategoriesTree}/${categoryTreeId}`
      }).then(() => {
         refetch();
         message.success("Categories Tree deleted successfully.");
      });
   };

   const onCategoryEdit = (record: any) => {
      console.log(record);
      setCategory(record);
   };

   return (
      <>
         <ActionHeader
            onSubmitSuccess={handleOnSubmitSuccess}
            initialCategoriesData={category}
            onCategoryEdit={onCategoryEdit}></ActionHeader>
         <CategoriesTreeTable
            onDeleteItem={onDeleteItem}
            onCategoryEdit={onCategoryEdit}
            data={data.data.data}
            loading={loading}
         />
      </>
   );
};
