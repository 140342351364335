import React from "react";
import { FormElementFieldMap } from "../../../../components/FormFields/FormElementFieldMap";

export function getFormFieldElement({
   jsonSchema,
   setFormElements
}: {
   jsonSchema: any;
   setFormElements: React.Dispatch<any>;
}) {
   if (Array.isArray(jsonSchema)) {
      const tagsElement = jsonSchema.map((item, index) => {
         const fieldType = item.field.type;
         const TagName = FormElementFieldMap.get(fieldType);
         return <TagName key={index} {...item}></TagName>;
      });

      setFormElements(tagsElement);
   } else if (jsonSchema && jsonSchema.field.type) {
      console.log({ jsonSchema });
      const fieldType = jsonSchema.field.type;
      const TagName = FormElementFieldMap.get(fieldType);
      setFormElements(<TagName {...jsonSchema}></TagName>);
   }
}
