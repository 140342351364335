import React from "react";
import { Menu, Layout, Space } from "antd";
import {
   OneToOneOutlined,
   GoldOutlined,
   FormOutlined,
   HddOutlined,
   GatewayOutlined,
   GroupOutlined,
   UserOutlined,
   DatabaseOutlined
} from "@ant-design/icons";

import { Link, useLocation } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";

const { Sider } = Layout;

export const SideBarMenu = () => {
   const location = useLocation();

   return (
      <Sider
         breakpoint="lg"
         collapsedWidth="0"
         onBreakpoint={(broken) => {
            console.log(broken);
         }}
         onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
         }}>
         <div className="logo">
            <Space>
               <Link to="/">
                  <img
                     src="https://concular.de/wp-content/themes/concular/dist/img/concular_logo.svg"
                     alt="Concular"
                     width="35px"></img>
               </Link>
               Admin App
            </Space>
         </div>
         <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["/products"]}
            defaultOpenKeys={["/forms"]}
            selectedKeys={[location.pathname]}>
            <Menu.Item key="/materials_request" icon={<OneToOneOutlined />}>
               <Link to="/materials_request">Materials Request</Link>
            </Menu.Item>
            <Menu.Item key="/users" icon={<UserOutlined />}>
               <Link to="/users">App Users</Link>
            </Menu.Item>
            <Menu.Item key="/products" icon={<DatabaseOutlined />}>
               <Link to="/products">Catalogue Item</Link>
            </Menu.Item>
            <Menu.Item key="/products-group" icon={<GroupOutlined />}>
               <Link to="/products-group">Products Group</Link>
            </Menu.Item>
            <Menu.Item key="/categoriesTree" icon={<GoldOutlined />}>
               <Link to="/categoriesTree">Categories Tree</Link>
            </Menu.Item>
            <Menu.Item key="/brands" icon={<OneToOneOutlined />}>
               <Link to="/brands">Brands</Link>
            </Menu.Item>
            <Menu.Item key="/materials" icon={<OneToOneOutlined />}>
               <Link to="/materials">Materials</Link>
            </Menu.Item>
            <SubMenu
               title={<span>Forms</span>}
               icon={<FormOutlined />}
               key="/forms">
               <Menu.Item key="/forms/templates" icon={<HddOutlined />}>
                  <Link to="/forms/templates">Templates</Link>
               </Menu.Item>
               <Menu.Item key="/forms/fields" icon={<GatewayOutlined />}>
                  <Link to="/forms/fields">Fields</Link>
               </Menu.Item>
            </SubMenu>
         </Menu>
      </Sider>
   );
};
