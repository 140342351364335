import React, { useState } from "react";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { ProductsTable } from "./ProductsTable";
import { Button } from "antd";
import { AddEditProduct } from "./AddEditProduct/index";
import "./style.scss";

export const ProductsViews = () => {
   const [showAddEditForm, setShowAddEditForm] = useState(false);
   const [{ data, loading, error }, refetch] = useAxios(
      Resource.path.getProducts
   );

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page</p>;

   const handleShowAddEditForm = () => {
      setShowAddEditForm(true);
   };
   const handleAddEditFormCancelEvent = () => {
      setShowAddEditForm(false);
   };

   const handleAddEditFormSubmitEvent = () => {
      setShowAddEditForm(false);
      refetch();
   };

   return (
      <>
         <div className="action-header-wrapper">
            <Button type="primary" size="large" onClick={handleShowAddEditForm}>
               Add Product
            </Button>
         </div>
         {showAddEditForm && (
            <AddEditProduct
               showForm={showAddEditForm}
               onSubmitSuccess={handleAddEditFormSubmitEvent}
               onCancel={handleAddEditFormCancelEvent}></AddEditProduct>
         )}
         <ProductsTable data={data.data.data} loading={loading} />
      </>
   );
};
