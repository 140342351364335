const BASE_API_URL: any = process.env.REACT_APP_API_BASE_URL;
const MATERIAL_SERVICE_BASE_API_URL: any =
   "https://asterix-prod.concular.com/material-service";

export const Resource = {
   path: {
      getCategories: `${BASE_API_URL}/categories`,
      createCategories: `${BASE_API_URL}/categories`,
      updateCategories: `${BASE_API_URL}/categories`,
      deleteCategories: `${BASE_API_URL}/categories`,
      getSignedUrlForCategoryAssets: `${BASE_API_URL}/categories/initiateUpload`,
      getCategoriesProductGroups: `${BASE_API_URL}/categories-product-groups`,
      getCategoriesByTreeId: `${BASE_API_URL}/categories/tree`,

      getCategoriesTree: `${BASE_API_URL}/categoriesTree`,
      createCategoriesTree: `${BASE_API_URL}/categoriesTree`,
      deleteCategoriesTree: `${BASE_API_URL}/categoriesTree`,
      updateCategoriesTree: `${BASE_API_URL}/categoriesTree`,

      getCategoriesTypeAheadSearchPath(keywords: string) {
         return `${process.env.REACT_APP_API_CATEGORIES_TYPEAHEAD_SEARCH_BASE_URL}?q=${keywords}&suggester=categories_name_suggestor`;
      },

      getFormTemplates: `${BASE_API_URL}/forms`,
      createFormTemplates: `${BASE_API_URL}/forms`,
      getFormTemplateById: `${BASE_API_URL}/forms`,
      deleteFormTemplateById: `${BASE_API_URL}/forms`,
      updateFormTemplate: `${BASE_API_URL}/forms`,

      getFormFields: `${BASE_API_URL}/formsfields`,
      createFormFields: `${BASE_API_URL}/formsfields`,
      deleteFormFields: `${BASE_API_URL}/formsfields`,
      updateFormFields: `${BASE_API_URL}/formsfields`,

      getBrands: `${BASE_API_URL}/brands`,
      createBrands: `${BASE_API_URL}/brands`,
      removeBrands: `${BASE_API_URL}/brands`,
      getSignedUrlForBrandAssets: `${BASE_API_URL}/brands/initiateUpload`,

      getProducts: `${BASE_API_URL}/products`,
      createProduct: `${BASE_API_URL}/products`,
      removeProduct: `${BASE_API_URL}/products`,
      getSignedUrlForProductAssets: `${BASE_API_URL}/products/initiateUpload`,

      getProductGroups: `${BASE_API_URL}/productGroups`,
      createProductGroups: `${BASE_API_URL}/productGroups`,
      updateProductGroups: `${BASE_API_URL}/productGroups`,
      removeProductGroups: `${BASE_API_URL}/productGroups`,
      getSignedUrlForProductGroupAssets: `${BASE_API_URL}/productGroups/initiateUpload`,

      getAsterixAppUsers: `${BASE_API_URL}/app/asterix/users`,
      updateAsterixAppUsers: `${BASE_API_URL}/app/asterix/users`,
      createAsterixAppUsers: `${BASE_API_URL}/app/asterix/users`,
      resetAsterixAppUsersPassword: `${BASE_API_URL}/app/asterix/users/reset_password`,

      getMaterials: `${BASE_API_URL}/materialIngredient`,
      createMaterials: `${BASE_API_URL}/materialIngredient`,
      removeMaterials: `${BASE_API_URL}/materialIngredient`,

      getAllMaterialRequests: `${MATERIAL_SERVICE_BASE_API_URL}/materials/requests`,
      updateCatMappingOnPGChange: `${BASE_API_URL}/productGroups/mapping/updateCategoryMapping`,

      getOekobaudat(uuid: string, dataStockId: string) {
         return `${BASE_API_URL}/oekobaudat/${uuid}/${dataStockId}`;
      }
   }
};
