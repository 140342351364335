import { Space, Table, Tag, Image, Popconfirm } from "antd";
import React, { useState } from "react";
import {
   EditTwoTone,
   DeleteTwoTone,
   EyeTwoTone,
   EditFilled,
   GoldOutlined
} from "@ant-design/icons";

export const ProductGroupTable = ({
   data,
   onDeleteProductGroup,
   onProductGroupEdit,
   showMapping,
   setParams,
   loading,
   pagination,
   searchTerm
}: any) => {
   const columns: any = [
      {
         title: "Image",
         dataIndex: "product_group_image_url",
         key: "product_group_image_url",
         width: 150,
         fixed: "left",
         render: (imageUrl: any) => {
            return imageUrl && <Image src={imageUrl} width={60}></Image>;
         }
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         width: 250,
         fixed: "left"
      },
      {
         title: "Unit",
         dataIndex: "unit",
         key: "unit",
         width: 250,
         fixed: "left"
      },
      {
         title: "Description",
         dataIndex: "description",
         key: "description",
         width: 500
      },
      {
         title: "Metafield",
         dataIndex: "metafield",
         key: "metafield",
         width: 300
      },
      {
         title: "High Min",
         dataIndex: "high_min",
         key: "high_min",
         width: 300
      },
      {
         title: "High Max",
         dataIndex: "high_max",
         key: "high_max",
         width: 300
      },
      {
         title: "Medium Min",
         dataIndex: "medium_min",
         key: "medium_min",
         width: 300
      },
      {
         title: "Medium Max",
         dataIndex: "medium_max",
         key: "medium_max",
         width: 300
      },
      {
         title: "Low Min",
         dataIndex: "low_min",
         key: "low_min",
         width: 300
      },
      {
         title: "Low Max",
         dataIndex: "low_max",
         key: "low_max",
         width: 300
      },
      {
         title: "Expected life time",
         dataIndex: "expected_life_time",
         key: "expected_life_time",
         width: 300
      },
      {
         title: "Action",
         key: "action",
         width: 130,
         fixed: "right",

         render: (record: any) => (
            <Space size="middle">
               <Popconfirm
                  title="Are you sure to delete this product group ?"
                  onConfirm={() => onDeleteItem(record.primary_id)}
                  okText="Yes"
                  cancelText="No">
                  <DeleteTwoTone />
               </Popconfirm>
               <EditFilled onClick={() => onItemSelectedForEdit(record)} />
               <GoldOutlined onClick={() => { showMapping(record) }} />
            </Space>
         )
      }
   ];

   const onDeleteItem = (product_group_id: string) => {
      onDeleteProductGroup(product_group_id);
   };

   const onItemSelectedForEdit = (record: any) => {
      onProductGroupEdit(record);
   };

   return (
      <Table
      loading={loading}
      pagination={{...pagination, showSizeChanger: false}}
      onChange={(value:any)=>{
         setParams({
            cursor: value.current - 1,
            limit: pagination.pageSize,
            search: searchTerm || null
         })
      }}
         dataSource={data}
         columns={columns}
         scroll={{ x: 1300 }}
         rowKey={(record) => record.id}></Table>
   );
};
