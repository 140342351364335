import React from "react";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { MaterialRequestTable } from "./MaterialRequestTable";

export const MaterialRequestViews = () => {
   const [{ data, loading, error }, refetch] = useAxios(
      Resource.path.getAllMaterialRequests
   );

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page</p>;

   const handleOnSubmitSuccess = () => {
      refetch();
   };

   return (
      <>
         <MaterialRequestTable
            data={data?.data?.data}
            loading={loading}
            refetch={handleOnSubmitSuccess}
         />
      </>
   );
};
