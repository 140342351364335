import React, { useState } from "react";
import { Form, Select } from "antd";
import * as AntIconComponents from "@ant-design/icons";
import Joi from "joi";
import { SwatchesPicker } from "react-color";
import colorsEN from './colors-en.json';

export const ColorSwatchFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("colorswatch")
   }).unknown()
});

export interface ColorSwatchField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      type: "colorswatch";
      [key: string]: any;
   };
}

export type AnyIconType = keyof typeof AntIconComponents;

const getIconComponent = (iconName: AnyIconType) => {
   const IconComponent: any = AntIconComponents[iconName];
   return IconComponent && <IconComponent />;
};

export const ColorSwatchField = (props: ColorSwatchField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   const formattedProps = {
      ...fieldProps,
      ...(fieldProps.prefix && getIconComponent(fieldProps.prefix)
         ? { prefix: getIconComponent(fieldProps.prefix) }
         : {}),
      ...(fieldProps.suffix && getIconComponent(fieldProps.suffix)
         ? { suffix: getIconComponent(fieldProps.suffix) }
         : {})
   };

   return (
      <Form.Item {...formItemProps}>
         <CustomSwatchColorField></CustomSwatchColorField>
      </Form.Item>
   );
};

const CustomOptions = (props: any) => {
   const { hex, name } = props;
   return (
      <div>
         <div style={{ background: hex, padding: "0px 10px 0px", display: "inline", marginLeft: "2px", border: '1px solid #d9d9d9' }}>&nbsp;</div>
         <span
            style={{ display: "inline", marginLeft: "5px" }}>
            {name}
         </span>
      </div>
   );
}

const CustomSwatchColorField = ({
   value = ' ',
   onChange
}: any) => {
   const [colors,] = useState<{
      [key: string]: string;
   }>(colorsEN);
   const { Option } = Select;

   const filterOption = (input: any, option: any) => {
      const dataText = colors[`${option.value}`];
      return dataText.toLowerCase().indexOf(input.toLowerCase()) >= 0
   } 

   return (
      <Select style={{ width: 250, marginRight: 10 }}  onSelect={(selected) => {
         onChange && onChange(selected);
      }} showSearch filterOption={filterOption}>
         {Object.keys(colors).map((hex) =>
            <Option value={hex}><CustomOptions hex={hex} name={colors[`${hex}`]} /></Option>
         )}
      </Select>
   );
};
