import React, { useState } from "react";
import { Button, Input, Row, Col } from "antd";
import "./style.scss";
import { AddEditProductGroup } from "../AddEditProductGroups/AddEditProductGroup";

const ActionHeader = ({
   onSubmitSuccess,
   initialProductGroupData,
   onProductGroupEdit,
   onSearch,
   searchTerm,
   productGroups
}: any) => {
   const [showAddEditForm, setShowAddEditForm] = useState(false);
   const handleShowAddEditForm = () => {
      setShowAddEditForm(true);
   };
   const handleAddEditFormCancelEvent = () => {
      setShowAddEditForm(false);
      onProductGroupEdit(null);
   };

   const handleAddEditFormSubmitEvent = () => {
      setShowAddEditForm(false);
      onProductGroupEdit(null);
   };

   const handleOnEditSuccess = () => {
      setShowAddEditForm(false);
      onSubmitSuccess(true);
      onProductGroupEdit(null);
   };

   React.useEffect(() => {
      //console.log({ initialProductGroupData });

      if (initialProductGroupData) {
         setShowAddEditForm(true);
      } else {
         setShowAddEditForm(false);
      }
   }, [initialProductGroupData]);

   return (
      <>
         <div className="action-header-wrapper">
            <Row gutter={[16, 8]}>
               <Col><Input.Search
                  placeholder="input search text"
                  allowClear
                  enterButton="Search"
                  size="large"
                  value={searchTerm}
                  onSearch={onSearch}
               /></Col>
               <Col><Button type="primary" size="large" onClick={handleShowAddEditForm}>
                  Add Product Group
               </Button></Col>
            </Row>
         </div>
         {showAddEditForm && (
            <AddEditProductGroup
               onSubmitSuccess={onSubmitSuccess}
               showForm={showAddEditForm}
               initialData={initialProductGroupData}
               onEditSuccess={handleOnEditSuccess}
               onSubmit={handleAddEditFormSubmitEvent}
               onCancel={handleAddEditFormCancelEvent}
               productGroups={productGroups}
               ></AddEditProductGroup>
         )}
      </>
   );
};

export default ActionHeader;
