import React, { useRef, useState, useEffect } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import "./style.css";
import Ajv from "ajv";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/tomorrow_night_blue";
import { Select } from "antd";
import { GenericFieldTypeList } from "../FormFields/jsonData";
const ajv = new Ajv({ allErrors: true, verbose: true });

export const getGenericFieldList = () => {
   return Object.keys(GenericFieldTypeList).map((key: any, index: number) => {
      return (
         <Select.Option
            key={index}
            size="large"
            value={JSON.stringify(GenericFieldTypeList[key])}>
            {key.toUpperCase()}
         </Select.Option>
      );
   });
};

export const JSONEditor = ({ initialValue, onChange }: any) => {
   const [value, setValue] = useState(JSON.parse("{}"));
   const jsonEditorRef: any = useRef(null);

   const fieldListOptions = getGenericFieldList();
   const onFieldSelected = (selectedValue: any) => {
      const parsedValue = JSON.parse(selectedValue);
      setValue(parsedValue);
   };

   useEffect(() => {
      setValue(initialValue);
   }, [initialValue]);

   useEffect(() => {
      if (jsonEditorRef.current !== null) {
         jsonEditorRef?.current.set(value);
         onChange(value);
      }
   }, [value]);

   const setRef = (instance: any) => {
      if (instance) {
         jsonEditorRef.current = instance.jsonEditor;
      } else {
         jsonEditorRef.current = null;
      }
   };

   return (
      <>
         <Select
            style={{ width: "200px" }}
            placeholder="Select or search a field"
            size="small"
            className="generic-field-select"
            onChange={onFieldSelected}>
            {fieldListOptions}
         </Select>
         <Editor
            ref={setRef}
            onChange={onChange}
            mode="code"
            value={value}
            ajv={ajv}
            ace={ace}
            theme="ace/theme/tomorrow_night_blue"
            indentation={4}
            className="json-editor"
         />
      </>
   );
};
