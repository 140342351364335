import React from "react";
import useAxios from "axios-hooks";
import { Resource } from "../../../../api/common";
import { FieldsTable } from "./FieldsTable/index";
import NavigationHeader from "../NavigationHeader/index";
import { message } from "antd";

export const FieldListViews = () => {
   const [{ data, loading, error }, refetch] = useAxios(
      {
         url: Resource.path.getFormFields,
         method: "GET"
      },
      { useCache: false }
   );

   const [
      { data: deleteData, loading: deleteLoading, error: deleteError },
      executeDelete
   ] = useAxios(
      {
         method: "delete"
      },
      { manual: true }
   );

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page</p>;

   const onDeleteItem = (fieldId: string) => {
      executeDelete({
         url: `${Resource.path.deleteFormFields}/${fieldId}`
      }).then(() => {
         refetch();
         message.success("Form Field deleted successfully.");
      });
   };

   return (
      <>
         <NavigationHeader></NavigationHeader>
         <FieldsTable
            onDeleteItem={onDeleteItem}
            data={data.data.data}
            loading={loading}></FieldsTable>
      </>
   );
};
