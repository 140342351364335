import React, { useEffect, useState } from "react";
import { Drawer, Button, Form, Col, Row, Input, message } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
import { ImageUploader } from "../../../components/ImageUploader/index";
import * as uuid from "uuid";
import axios from "axios";
import { Auth } from "aws-amplify";

const BrandsForm = ({ children, onFinish }: any) => {
   const [form] = Form.useForm();

   const handleFileChange = (fileList: any) => {
      const [brandImage] = fileList;

      form.setFieldsValue({ brandImage: brandImage });
   };

   return (
      <Form
         layout="vertical"
         form={form}
         onFinish={onFinish}
         name="brands_form">
         <Row gutter={16}>
            <Col span="12">
               <Form.Item
                  label="Brand Name"
                  name="name"
                  rules={[
                     {
                        required: true,
                        message: "Please enter brand name"
                     }
                  ]}>
                  <Input placeholder="Brand Name" size="large" />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item label="Brand Description" name="description">
                  <Input.TextArea
                     rows={4}
                     placeholder="Brand Description"></Input.TextArea>
               </Form.Item>
            </Col>
         </Row>

         <Form.Item name="brandImage">
            <ImageUploader onFileChange={handleFileChange}></ImageUploader>
         </Form.Item>
         {children}
      </Form>
   );
};

export const AddEditBrandsForm = ({
   showForm,
   onSubmitSuccess,
   onCancel
}: any) => {
   const [
      { data, loading: postLoading, error: postError },
      executePost
   ] = useAxios(
      {
         url: Resource.path.createBrands,
         method: "POST"
      },
      {
         manual: true,
         useCache: true
      }
   );

   const handleOnSubmit = async (values: any) => {
      const key = "addBrandsLoadingKey";
      message.loading({ content: "Loading...", key });

      let uploadedBrandImageUrl: string = "";
      let brandImage: {
         file_name: string;
         file_path: string;
         object_url: string;
      } | null = null;

      if (values.brandImage) {
         const brandImageObj = values.brandImage;

         // Create Global Asset Id
         const assetId = `${uuid.v4()}_${Date.now()}`;
         const assetType = "brand_image";
         const accessToken = (await Auth.currentSession()).getIdToken();

         // Get Upload Signed URL
         const response = await axios.post(
            Resource.path.getSignedUrlForBrandAssets,
            {
               assetType,
               assetId,
               fileName: brandImageObj.name
            },
            { headers: { Authorization: accessToken.getJwtToken() } }
         );
         const result = response?.data?.data;
         if (result) {
            await axios.put(result.signedUrl, brandImageObj.originFileObj);
            uploadedBrandImageUrl = result.objectUrl;
            brandImage = {
               file_name: result.fileName,
               file_path: result.filePath,
               object_url: result.objectUrl
            };
         }
      }

      executePost({
         data: {
            name: values.name,
            ...(values.brandImage && brandImage && Object.keys(brandImage)
               ? { brand_image: brandImage }
               : {}),
            ...(uploadedBrandImageUrl
               ? { brand_image_url: uploadedBrandImageUrl }
               : {})
         }
      }).then(() => {
         message.success(
            {
               content: "Brand saved successfully",
               key
            },
            0
         );

         setTimeout(() => {
            message.destroy(key);
            onSubmitSuccess(true);
         }, 100);
      });
   };
   const handleOnCancel = () => {
      onCancel();
   };

   return (
      <Drawer
         title="Create a new Brand"
         width={"60vw"}
         onClose={handleOnCancel}
         visible={showForm}
         bodyStyle={{ paddingBottom: 60 }}>
         <BrandsForm onFinish={handleOnSubmit}>
            <footer>
               <div
                  style={{
                     textAlign: "right"
                  }}>
                  <Button onClick={handleOnCancel} style={{ marginRight: 8 }}>
                     Cancel
                  </Button>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={postLoading}>
                     Save Brands
                  </Button>
               </div>
            </footer>
         </BrandsForm>
      </Drawer>
   );
};
