import { Space, Table, Tag, Image, Popconfirm } from "antd";
import React from "react";
import { EditTwoTone, DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";

export const MaterialsTable = ({ data, onDeleteItem }: any) => {
   const columns: any = [
      {
         title: "Group Name 🇩🇪",
         dataIndex: "de_group_name",
         key: "de_group_name",
         width: 250,
         fixed: "left"
      },
      {
         title: "Name 🇩🇪",
         dataIndex: "de_material_name",
         key: "de_material_name"
      },
      {
         title: "Group Name 🇬🇧",
         dataIndex: "en_group_name",
         key: "en_group_name"
      },
      {
         title: "Name 🇬🇧",
         dataIndex: "en_material_name",
         key: "en_material_name"
      },
      {
         title: "Max",
         dataIndex: "max",
         key: "max"
      },
      {
         title: "Min",
         dataIndex: "min",
         key: "min"
      },
      {
         title: "Action",
         key: "action",
         width: 130,
         fixed: "right",

         render: ({ id }: any) => (
            <Space size="middle">
               <Popconfirm
                  title="Are you sure to delete this material ?"
                  onConfirm={() => onConfirm(id)}
                  okText="Yes"
                  cancelText="No">
                  <DeleteTwoTone />
               </Popconfirm>
            </Space>
         )
      }
   ];

   const onConfirm = (materialId: string) => {
      console.log({ materialId });

      onDeleteItem(materialId);
   };

   return (
      <Table
         pagination={false}
         dataSource={data}
         columns={columns}
         scroll={{ x: 1300 }}
         rowKey={(record) => record.id}></Table>
   );
};
