import React, { useState, useEffect } from "react";
import { Row, Col, Space, message, Select } from "antd";
import { Form, Input, Button } from "antd";
import { getFormFieldElement } from "./getFormFieldElement";
import { JSONEditor } from "../../../../components/JSONEditor/index";
import { Link, useLocation } from "react-router-dom";
import { isValidSchema } from "./isValidSchema";
import { Resource } from "../../../../api/common";
import useAxios from "axios-hooks";
import { useHistory } from "react-router-dom";
import "./style.css";
import * as uuid from "uuid";

export const AddEditFields = () => {
   const [form] = Form.useForm();
   const history = useHistory();
   const location = useLocation();

   const [formElements, setFormElements] = useState<any | any[]>("");
   const [jsonSchemaError, setJsonSchemaError] = useState<boolean>(true);
   const [jsonSchema, setJsonSchema] = useState<any>();
   const [editorJSONValue, setEditorJSONValue] = useState<any>({});
   const [field, setField] = useState<any>({});

   const isEditMode = !!location?.state;

   useEffect(() => {
      if (location?.state) {
         const { field }: any = location?.state;
         if (field) {
            setField(field);
            form.setFieldsValue({ field_name: field.field_name });
            form.setFieldsValue({ description: field.description });
            if (field.field_schema && field.field_schema.field && field.field_schema.field.type
               && field.field_schema.field.type === "colorswatchfield"
               && field.field_schema.field.defaultColor) {
               delete field.field_schema.field.defaultColor;
            }
            setEditorJSONValue(field.field_schema);
         }
      }
   }, [location]);

   const [
      { data, loading: postLoading, error: postError },
      executePost
   ] = useAxios(
      {
         url: Resource.path.createFormFields,
         method: "POST",
         headers: { "Content-Type": "*" }
      },
      {
         manual: true,
         useCache: false
      }
   );

   const [
      { data: updateData, loading: putLoading, error: putError },
      executePut
   ] = useAxios(
      {
         url: Resource.path.updateFormFields,
         method: "PUT",
         headers: { "Content-Type": "*" }
      },
      {
         manual: true,
         useCache: false
      }
   );

   const handleJSONInput = (jsonSchema: any) => {
      const hasValidJSON = isValidSchema(jsonSchema);
      if (hasValidJSON) {
         getFormFieldElement({ jsonSchema, setFormElements });
         setJsonSchemaError(false);
         setJsonSchema(jsonSchema);
      } else {
         setJsonSchemaError(true);
      }
   };

   const getFormItemName = (label: string) => {
      if (label && label.indexOf("_") !== -1) return label;
      return `${label || "NA"}_${uuid.v4()}`;
   };

   const handleCreateField = (values: any) => {
      form.validateFields();
      if (jsonSchemaError) {
         message.error("Please provide valid JSON schema for form field");
      } else {
         const key = "addFieldsLoadingKey";
         message.loading({ content: "Loading...", key });

         if (isEditMode) {
            if(field?.field_schema?.field?.key){
               jsonSchema.field.key = field.field_schema.field.key;
            }
            executePut({
               data: {
                  id: field.id,
                  field_name: values.field_name,
                  description: values.description,
                  field_schema: {
                     ...jsonSchema,
                     formItem: {
                        ...jsonSchema.formItem,
                        // Assign unique name to form field in format field label name_uniqueId
                        name: getFormItemName(field?.field_schema?.formItem?.name)
                     }
                  }
               }
            }).then(() => {
               message.success(
                  {
                     content: "Field added successfully",
                     key
                  },
                  0
               );

               setTimeout(() => {
                  message.destroy(key);
                  history.push("/forms/fields");
               }, 100);
            });
         } else {
            executePost({
               data: {
                  field_name: values.field_name,
                  description: values.description,
                  field_schema: {
                     ...jsonSchema,
                     formItem: {
                        ...jsonSchema.formItem,
                        // Assign unique name to form field in format field label name_uniqueId
                        name: `${jsonSchema.field.key ? jsonSchema.field.key.trim() : jsonSchema.formItem.label.trim()}_${uuid.v4()}`
                     }
                  }
               }
            }).then(() => {
               message.success(
                  {
                     content: "Field added successfully",
                     key
                  },
                  0
               );

               setTimeout(() => {
                  message.destroy(key);
                  history.push("/forms/fields");
               }, 100);
            });
         }
      }
   };

   if (postError) {
      message.error(postError);
   }

   return (
      <>
         <Form
            form={form}
            name="formFieldInputForm"
            layout="vertical"
            requiredMark={true}
            onFinish={handleCreateField}>
            <Form.Item
               label="Field Name"
               name="field_name"
               rules={[
                  {
                     required: true,
                     message: "Please input Field name"
                  }
               ]}>
               <Input placeholder="Field name" />
            </Form.Item>
            <Form.Item label="Description" name="description">
               <Input.TextArea />
            </Form.Item>
            <Row gutter={12}>
               <Col span={12} style={{ position: "relative" }}>
                  <JSONEditor
                     onChange={handleJSONInput}
                     initialValue={editorJSONValue}
                  />
               </Col>
               <Col span={12} className="field-preview">
                  <Form>
                     {Array.isArray(formElements)
                        ? formElements.map((element) => element)
                        : formElements}
                  </Form>
               </Col>
            </Row>

            <Form.Item>
               <Row style={{ marginTop: "15px" }}>
                  <Col span={12} offset={8}>
                     <Space>
                        <Button danger size="large" style={{ width: "150px" }}>
                           <Link to="/forms/fields">Cancel</Link>
                        </Button>
                        <Button
                           type="primary"
                           loading={postLoading}
                           size="large"
                           style={{ width: "200px" }}
                           htmlType="submit">
                           Save Field
                        </Button>
                     </Space>
                  </Col>
               </Row>
            </Form.Item>
         </Form>
      </>
   );
};
