import { Upload } from "antd";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";

const uploadButton = (
   <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
   </div>
);

function getBase64(file: any) {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });
}

export const ImageUploader = ({ onFileChange }: any) => {
   const [previewVisible, setPreviewVisible] = useState(false);
   const [previewImage, setPreviewImage] = useState();
   const [previewTitle, setPreviewTitle] = useState();
   const [fileList, setFileList] = useState([]);

   const handleOnChange = ({ fileList }: any) => {
      onFileChange(fileList);
      setFileList(fileList);
   };

   const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
         file.preview = await getBase64(file.originFileObj);
      }

      setPreviewVisible(true);
      setPreviewImage(file.url || file.preview);
      setPreviewTitle(
         file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
   };
   const handleCancel = () => {
      setPreviewVisible(false);
   };

   const handleBeforeUpload = () => {
      return false;
   };

   return (
      <>
         <Upload
            fileList={fileList}
            listType="picture-card"
            accept=".png, .jpeg, .jpg"
            multiple={true}
            beforeUpload={handleBeforeUpload}
            onPreview={handlePreview}
            onChange={handleOnChange}>
            {uploadButton}
         </Upload>
         <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}>
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
         </Modal>
      </>
   );
};
