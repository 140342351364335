import React from "react";
import ActionHeader from "./ActionHeader/ActionHeader";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { MaterialsTable } from "./MaterialsTable";
import { message } from "antd";

export const MaterialsViews = () => {
   const [{ data, loading, error }, refetch] = useAxios(
      Resource.path.getMaterials
   );

   const [
      { data: deleteData, loading: deleteLoading, error: deleteError },
      executeDelete
   ] = useAxios(
      {
         method: "delete"
      },
      { manual: true }
   );

   const onDeleteItem = (materialId: string) => {
      executeDelete({
         url: `${Resource.path.removeMaterials}/${materialId}`
      }).then(() => {
         refetch();
         message.success("Material deleted successfully.");
      });
   };

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error! Please reload page Please reload page</p>;
   const handleOnSubmitSuccess = (value: any) => {
      refetch();
   };
   return (
      <>
         <ActionHeader onSubmitSuccess={handleOnSubmitSuccess}></ActionHeader>
         <MaterialsTable
            onDeleteItem={onDeleteItem}
            data={data.data.data}
            loading={loading}
         />
      </>
   );
};
