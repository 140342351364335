import React from "react";
import { Col, Form, Row, Select } from "antd";

import { useMaterialGroups } from "../../../../hooks";
import { Material } from "../types";

type Props = {
   name: Array<number | string>;
   initial?: {
      parent_material_id?: string;
      sub_material_id?: string;
   };
   setSubMaterial?: (val: Material | undefined) => void;
   setMaterialGroup?: (val: string | undefined) => void;
   materials: Material[];
   required?: boolean
};

export const MaterialDropdown = ({
   name,
   initial,
   setSubMaterial,
   setMaterialGroup,
   materials,
   required = true
}: Props) => {
   const materialGroups = useMaterialGroups(materials);
   const [materialGroup, _setMaterialGroup] = React.useState<string>();

   if (initial?.parent_material_id && !materialGroup) {
      _setMaterialGroup(initial?.parent_material_id);
   }
   const [isSubMaterialSet, setIsSubMaterialSet] = React.useState<boolean>();
   if (initial?.sub_material_id && materials) {
      const tmp = materials?.find((a) => a.id === initial?.sub_material_id);
      if (tmp && !isSubMaterialSet) {
         setIsSubMaterialSet(true);
         setSubMaterial?.(tmp);
      }
   }

   return (
      <Row gutter={16}>
         <Col span="9">
            <Form.Item
               label="Material"
               rules={[
                  {
                     required,
                     message: "Please select material"
                  }
               ]}
               name={[...name, "parent_material_id"]}>
               <Select
                  onChange={(value: string) => {
                     _setMaterialGroup(value);
                     setMaterialGroup?.(value);
                  }}>
                  {Object.keys(materialGroups)?.map((materialGroupName) => {
                     return (
                        <Select.Option
                           value={materialGroupName}
                           key={materialGroupName}>
                           {materialGroupName}
                        </Select.Option>
                     );
                  })}
               </Select>
            </Form.Item>
         </Col>
         <Col span="9">
            <Form.Item
               label="Sub-material"
               rules={[
                  {
                     required,
                     message: "Please select Sub-material"
                  }
               ]}
               name={[...name, "sub_material_id"]}>
               <Select
                  onChange={(value, i) => {
                     const selectedMaterialTmp = materials?.find(
                        (a) => a.id === value
                     );
                     if (selectedMaterialTmp) {
                        setSubMaterial?.(selectedMaterialTmp);
                     }
                  }}
                  allowClear>
                  {materialGroups &&
                     materialGroup &&
                     materialGroups[materialGroup]?.map((material) => {
                        return (
                           <Select.Option
                              value={material.id}
                              key={material.de_material_name}>
                              {material.de_material_name}
                           </Select.Option>
                        );
                     })}
               </Select>
            </Form.Item>
         </Col>
      </Row>
   );
};
