import React from "react";
import { Row, Col, Form, Select, Input } from "antd";
import { Unit } from "../../../../hooks/useUnitFields";
import { FormInstance } from "antd/lib/form";

type Props = {
   unitFields: Unit[];
   form: FormInstance<any>;
   type: "width" | "height" | "depth";
   unit: "m/lfm" | "m²" | "m³";
   picture: React.ReactElement;
   current?: Dimension;
};

export type Dimension = {
   field_id: string;
   value: number;
   unit: string;
   result: number;
};

export function UnitFieldForm({
   unitFields,
   form,
   type,
   unit,
   picture,
   current
}: Props) {
   const [selectedField, setSelectedField] = React.useState<Unit>();

   React.useEffect(() => {
      if (current?.field_id) {
         const tmp = unitFields.find((a) => a.id === current.field_id);
         if (tmp) {
            setSelectedField(tmp);
         }
      }
   }, [current, selectedField, unitFields]);

   return (
      <>
         <Row gutter={16}>
            <Col span="7">
               <Form.Item
                  label={`Select ${type}`}
                  name={["conversion", type, "field_id"]}>
                  <Select
                     onChange={(value) => {
                        const selectedField = unitFields.find(
                           (a) => a.id === value
                        );
                        if (selectedField) {
                           setSelectedField(selectedField);
                        }
                     }}>
                     {unitFields?.map((a) => {
                        return (
                           <Select.Option value={a.id} key={a.id}>
                              {a.label}
                           </Select.Option>
                        );
                     })}
                  </Select>
               </Form.Item>
            </Col>
            {selectedField && (
               <>
                  <Col span="1">
                     <span>=</span>
                  </Col>
                  <Col span="5">
                     <div>&nbsp;</div>
                     <Form.Item
                        name={["conversion", type, "value"]}
                        initialValue={1}>
                        <Input type="number" addonAfter={unit}></Input>
                     </Form.Item>
                  </Col>
               </>
            )}
            <Col span="5">{picture}</Col>
         </Row>
         {selectedField && (
            <Row gutter={16}>
               {
                  <Col span="7">
                     <Form.Item name={["conversion", type, "result"]}>
                        <Input type="number" step="any"></Input>
                     </Form.Item>
                  </Col>
               }
               {
                  <Col span="7">
                     <Form.Item name={["conversion", type, "unit"]}>
                        <Select>
                           {selectedField.options.map((option) => {
                              return (
                                 <Select.Option key={option} value={option}>
                                    {option}
                                 </Select.Option>
                              );
                           })}
                        </Select>
                     </Form.Item>
                  </Col>
               }
            </Row>
         )}
      </>
   );
}
