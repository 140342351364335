import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
import axios from "axios";
import * as uuid from "uuid";
import { Auth } from "aws-amplify";
import { Drawer, Button, message, Form, Row, Col, Select, Spin } from "antd";
import { ProductGroupForm } from "./ProductGroupForm";

export const AddEditProductGroup = ({
   showForm,
   onSubmitSuccess,
   onCancel,
   initialData,
   onEditSuccess,
   productGroups
}: any) => {
   const [isEditMode, setEditMode] = useState(false);
   const [replacedByPg, setReplacedByPg] = useState([]);
   const [form] = Form.useForm();
   const [{ loading: postLoading }, executePost] = useAxios(
      {
         url: Resource.path.createProductGroups,
         method: "POST"
      },
      {
         manual: true,
         useCache: true
      }
   );
   const [{ data, loading, error }, refetch] = useAxios({
      url: Resource.path.getProductGroups
   }, {
      manual: false,
      useCache: false
   });

   const [, executePut] = useAxios(
      {
         url: Resource.path.updateProductGroups,
         method: "PUT"
      },
      {
         manual: true,
         useCache: true
      }
   );

   useEffect(() => {
      if (initialData && initialData?.id) {
         setEditMode(true);
        console.log(initialData);
      }
      //console.log(productGroups)
      if(data?.data?.data){
         setReplacedByPg(data?.data?.data);
      }
   }, [initialData, productGroups, data]);

   const handleOnSubmit = async (values: any) => {
      const key = "addProductGroupLoadingKey";
      message.loading({ content: "Loading...", key });
    //  console.log(values);
      form.validateFields().then(async () => {

         let uploadedProductGroupImageUrl: string = "";
         let productGroupImage: {
            file_name: string;
            file_path: string;
            object_url: string;
         } | null = null;

         if (values.productGroupImage) {
            const productGroupImageObj = values.productGroupImage;

            // Create Global Asset Id
            const assetId = `${uuid.v4()}_${Date.now()}`;
            const assetType = "product_group_image";
            const accessToken = (await Auth.currentSession()).getIdToken();

            // Get Upload Signed URL
            const response = await axios.post(
               Resource.path.getSignedUrlForProductGroupAssets,
               {
                  assetType,
                  assetId,
                  fileName: productGroupImageObj.name
               },
               { headers: { Authorization: accessToken.getJwtToken() } }
            );
            const result = response?.data?.data;
            if (result) {
               await axios.put(
                  result.signedUrl,
                  productGroupImageObj.originFileObj
               );
               uploadedProductGroupImageUrl = result.objectUrl;
               productGroupImage = {
                  file_name: result.fileName,
                  file_path: result.filePath,
                  object_url: result.objectUrl
               };
            }
         }

         values?.productGroupImage &&
            Reflect.deleteProperty(values, "productGroupImage");
         if (isEditMode) {
            executePut({
               data: {
                  ...initialData,
                  ...values,
                  ...(productGroupImage && Object.keys(productGroupImage)
                     ? { product_group_image: productGroupImage }
                     : {}),
                  ...(uploadedProductGroupImageUrl
                     ? {
                        product_group_image_url: uploadedProductGroupImageUrl
                     }
                     : {})
               }
            }).then(() => {
               message.success(
                  {
                     content: "Product Group edited successfully",
                     key
                  },
                  0
               );

               setTimeout(() => {
                  message.destroy(key);
                  onEditSuccess(true);
               }, 100);
            }).catch((e) => {
               message.destroy(key);
               message.error(e.message)
            });
         } else {
            executePost({
               data: {
                  ...values,
                  ...(productGroupImage && Object.keys(productGroupImage)
                     ? { product_group_image: productGroupImage }
                     : {}),
                  ...(uploadedProductGroupImageUrl
                     ? { product_group_image_url: uploadedProductGroupImageUrl }
                     : {})
               }
            }).then(() => {
               message.success(
                  {
                     content: "Product Group saved successfully",
                     key
                  },
                  0
               );

               setTimeout(() => {
                  message.destroy(key);
                  onSubmitSuccess(true);
               }, 100);
            }).catch((e) => {
               message.destroy(key);
               message.error(e.message)
            });
         }

      }).catch((error) => {
         console.log(error);
         message.destroy(key);
         message.error(error.message)
      })

   };
   const handleOnCancel = () => {
      onCancel();
   };

   return (
      <Drawer
         title={`${isEditMode ? "Edit Product Group" : "Create a new Product Group"
            }`}
         width={"60vw"}
         onClose={handleOnCancel}
         visible={showForm}
         bodyStyle={{ paddingBottom: 80 }}>
         <Spin spinning={loading}>   
         <ProductGroupForm onFinish={handleOnSubmit} initialValue={initialData} form={form}>
            {
               !!isEditMode && productGroups.length && <Row gutter={16}>
               <Col span="12">
                  <Form.Item
                     label="Replace By Product Group"
                     name="replaced_by_id"
                     rules={[
                        {
                           required: false,
                           message: "Please select a product group for replace"
                        }
                     ]}>
                     <Select allowClear>
                        {
                           replacedByPg.filter((rec:any)=>{
                              return rec.id !== initialData.id;
                           }).map((rec:any)=>
                              <Select.Option value={rec.id}>{rec.name}</Select.Option>
                           )
                        }
                     </Select>
                  </Form.Item>
               </Col>
            </Row>
            }
            <footer>
               <div
                  style={{
                     textAlign: "right"
                  }}>
                  <Button onClick={handleOnCancel} style={{ marginRight: 8 }}>
                     Cancel
                  </Button>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={postLoading}>
                     Save Product Group
                  </Button>
               </div>
            </footer>
         </ProductGroupForm>
         </Spin>
      </Drawer>
   );
};
