import React, { useEffect, useState } from "react";
import { Drawer, Button, Form, Col, Row, Input, message, Select } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
import axios from "axios";
import * as uuid from "uuid";
import CategoriesSearchField from "../CategoriesSearchField";
import ProductGroupMultipleSelectField from "../../ProductGroups/ProductGroupMultipleSelectField";
import { useParams } from "react-router-dom";

const CategoriesForm = ({ children, onFinish, initialValue }: any) => {
   const [form] = Form.useForm();

   useEffect(() => {
      if (initialValue) {
         form.setFieldsValue({
            parentCategory: {
               name: initialValue?.parent_category_name,
               id: initialValue?.parent_id
            }
         });
      }
   }, [initialValue]);

   const onCategorySelected = (value: { id: string; name: string }) => {
      form.setFieldsValue({ parentCategory: value });
   };

   const onProductGroupSelected = (values: any[]) => {
      form.setFieldsValue({ product_groups: values });
   };

   return (
      <Form
         layout="vertical"
         onFinish={onFinish}
         name="categories_form"
         form={form}
         initialValues={initialValue}>
         <Row gutter={16}>
            <Col span="12">
               <Form.Item
                  label="Category Name"
                  name="name"
                  rules={[
                     { required: true, message: "Please enter category name" }
                  ]}>
                  <Input placeholder="Category Name" size="large" />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item label="Parent Category" name="parentCategory">
                  <CategoriesSearchField
                     onFieldSelect={onCategorySelected}></CategoriesSearchField>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item label="Product Groups" name="product_groups">
                  <ProductGroupMultipleSelectField
                     onFieldSelect={
                        onProductGroupSelected
                     }></ProductGroupMultipleSelectField>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="18">
               <Form.Item label="Categories Description" name="description">
                  <Input.TextArea
                     rows={4}
                     placeholder="Categories Description"></Input.TextArea>
               </Form.Item>
            </Col>
         </Row>

         {children}
      </Form>
   );
};

export const AddEditCategories = ({
   showForm,
   onSubmitSuccess,
   onCancel,
   initialData,
   onEditSuccess
}: any) => {
   const [isEditMode, setEditMode] = useState(false);
   let { id: categoriesTreeId }: any = useParams();

   const [{ data, loading: postLoading, error: postError }, executePost] =
      useAxios(
         {
            url: Resource.path.createCategories,
            method: "POST"
         },
         {
            manual: true,
            useCache: true
         }
      );

   const [{ data: putData, loading: putLoading, error: putError }, executePut] =
      useAxios(
         {
            url: Resource.path.updateCategories,
            method: "PUT"
         },
         {
            manual: true,
            useCache: true
         }
      );

   useEffect(() => {
      if (initialData && initialData?.id) {
         setEditMode(true);
      }
   }, [initialData]);

   const handleOnSubmit = async (values: any) => {
      const key = "addCategoriesLoadingKey";
      message.loading({ content: "Loading...", key });

      const parentCategory = values?.parentCategory;
      values?.parentCategory &&
         Reflect.deleteProperty(values, "parentCategory");

      if (isEditMode) {
         executePut({
            data: {
               ...initialData,
               ...values,
               categories_tree_id: categoriesTreeId,

               ...(parentCategory
                  ? {
                       parent_id: parentCategory.id,
                       parent_category_name: parentCategory.name
                    }
                  : {})
            }
         }).then(() => {
            message.success(
               {
                  content: "Categories edited successfully",
                  key
               },
               0
            );

            setTimeout(() => {
               message.destroy(key);
               onEditSuccess(true);
            }, 100);
         });
      } else {
         executePost({
            data: {
               ...values,
               categories_tree_id: categoriesTreeId,
               ...(parentCategory
                  ? {
                       parent_id: parentCategory.id,
                       parent_category_name: parentCategory.name
                    }
                  : {})
            }
         }).then(() => {
            message.success(
               {
                  content: "Categories saved successfully",
                  key
               },
               0
            );

            setTimeout(() => {
               message.destroy(key);
               onSubmitSuccess(true);
            }, 100);
         });
      }
   };
   const handleOnCancel = () => {
      onCancel();
   };

   return (
      <Drawer
         title={`${isEditMode ? "Edit Category" : "Create a new Categories"}`}
         width={"60vw"}
         onClose={handleOnCancel}
         visible={showForm}
         bodyStyle={{ paddingBottom: 80 }}>
         <CategoriesForm onFinish={handleOnSubmit} initialValue={initialData}>
            <footer>
               <div
                  style={{
                     textAlign: "right"
                  }}>
                  <Button onClick={handleOnCancel} style={{ marginRight: 8 }}>
                     Cancel
                  </Button>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={postLoading}>
                     Save Categories
                  </Button>
               </div>
            </footer>
         </CategoriesForm>
      </Drawer>
   );
};
