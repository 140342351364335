import React from "react";
import { FormTemplate } from "../views/ProductGroups/AddEditProductGroups/types";

export type Unit = {
   id: string;
   label: string;
   name: string;
   options: string[];
};

export function useUnitFields(
   productGroupId?: string,
   formTemplates?: FormTemplate[]
) {
   const [unitFields, setUnitFields] = React.useState<Unit[]>();

   React.useEffect(() => {
      const formTemplate = formTemplates?.find(
         (a) => a.product_group_id === productGroupId
      );
      const tmp = formTemplate?.template.filter((a) => {
         return a.field.type === "unitfield";
      });
      console.log(tmp);
      if (tmp)
         setUnitFields(
            tmp.map((a) => ({
               ...a.formItem,
               id: a.id,
               options: a.field.options
            }))
         );
   }, [formTemplates, productGroupId]);

   return unitFields;
}
