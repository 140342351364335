import React, { useEffect, useState } from "react";
import { Form, Col, Row, Input } from "antd";
// import CategoriesSearchField from "../../Categories/CategoriesSearchField";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
import { FormView } from "./FormView";
import BrandSearchField from "../../Brands/BrandSearchField";
import { ImageUploader } from "../../../components/ImageUploader";
import ProductGroupField from "../ProductGroupSelectField";
import CategoriesTreeSearchField from "../../Categories/CategoriesTreeSearchField";

export const ProductForm = ({ children, onFinish }: any) => {
   const [formSchema, setFormSchema] = useState<any>([]);
   const [form] = Form.useForm();
   const [selectedCategoryId, setSelectedCategoryId] = useState<any>(undefined);

   const [
      { data, loading: postLoading, error: postError },
      executeGet
   ] = useAxios(
      {
         method: "GET"
      },
      {
         manual: true,
         useCache: true
      }
   );

   const onCategorySelected = (value: { id: string; name: string }) => {
      const { id: categoryId } = value;
      form.setFieldsValue({ category: value });
      setSelectedCategoryId(categoryId);
   };

   const onBrandSelected = (value: { id: string; name: string }) => {
      form.setFieldsValue({ brand: value });
   };

   useEffect(() => {
      if (data) {
         const schema = data.data.Item;
         schema && setFormSchema(schema.template);
      }
   }, [data]);

   const handleFileChange = (fileList: any) => {
      form.setFieldsValue({ productImages: fileList });
   };

   const handleOnProductGroupFieldSelected = (value: any) => {
      executeGet({
         url: `${Resource.path.getFormTemplateById}/${value.key}`
      });
      form.setFieldsValue({ product_group: value });
   };

   const handleOnValueChange = (changes: any, allValues: any) => {
      console.log({ allValues });
   };

   return (
      <Form
         layout="vertical"
         onFinish={onFinish}
         name="product_form"
         form={form}
         onValuesChange={handleOnValueChange}>
         <Row gutter={16}>
            <Col span="8">
               <Form.Item label="Category" name="category">
                  <CategoriesTreeSearchField
                     onFieldSelect={
                        onCategorySelected
                     }></CategoriesTreeSearchField>
               </Form.Item>
            </Col>
            <Col span="8">
               <Form.Item
                  label="Product Group"
                  name="product_group"
                  rules={[
                     { required: true, message: "Please select product group" }
                  ]}>
                  <ProductGroupField
                     onFieldSelect={handleOnProductGroupFieldSelected}
                     categoryId={selectedCategoryId}></ProductGroupField>
               </Form.Item>
            </Col>

            <Col span="8">
               <Form.Item
                  label="Product Title"
                  name="title"
                  rules={[
                     { required: true, message: "Please enter product title" }
                  ]}>
                  <Input placeholder="Product Title" size="large" />
               </Form.Item>
            </Col>
            <Col span="8">
               <Form.Item label="Brands" name="brand">
                  <BrandSearchField
                     onFieldSelect={onBrandSelected}></BrandSearchField>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span="8">
               <Form.Item label="Product Description" name="description">
                  <Input.TextArea
                     rows={4}
                     placeholder="Product Description"></Input.TextArea>
               </Form.Item>
            </Col>
            <Col span="8">
               <Form.Item label="Notes" name="notes">
                  <Input.TextArea rows={4} placeholder="Notes"></Input.TextArea>
               </Form.Item>
            </Col>
            <Col span="8">
               <Form.Item label="EAN" name="ean">
                  <Input size="large"></Input>
               </Form.Item>
            </Col>
         </Row>
         <Row>
            <Col span="8">
               <Form.Item name="specification">
                  <FormView schema={formSchema}></FormView>
               </Form.Item>
            </Col>
         </Row>
         <Form.Item name="productImages">
            <ImageUploader onFileChange={handleFileChange}></ImageUploader>
         </Form.Item>

         {children}
      </Form>
   );
};
