import React from "react";
import { Form, InputNumber } from "antd";
import * as Joi from "joi";

export const NumberFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("number")
   }).unknown()
});

export interface NumberField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      [key: string]: any;
   };
}

export const NumberField = (props: NumberField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   return (
      <Form.Item {...formItemProps}>
         <InputNumber {...fieldProps}></InputNumber>
      </Form.Item>
   );
};
