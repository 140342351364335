import React from "react";
import { Switch, Route } from "react-router-dom";
import { AddEditTemplateViews } from "./AddEditTemplateViews";
import { ListViews } from "./ListViews";

export const TemplateView = () => {
   return (
      <Switch>
         <Route exact path="/forms/templates">
            <ListViews />
         </Route>
         <Route path="/forms/templates/:operation">
            <AddEditTemplateViews />
         </Route>
      </Switch>
   );
};
