import { Space, Table, Tag, Image } from "antd";
import React from "react";
import { EditTwoTone, DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";

const columns: any = [
   {
      title: "Image",
      dataIndex: "brand_image_url",
      key: "brand_image_url",
      width: 150,
      fixed: "left",
      render: (imageUrl: any) => {
         return imageUrl && <Image src={imageUrl} width={60}></Image>;
      }
   },
   {
      title: "Brand Name",
      dataIndex: "name",
      key: "name",
      width: 250,
      fixed: "left"
   },
   {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 500
   },
   {
      title: "Metafield",
      dataIndex: "metafield",
      key: "metafield",
      width: 300
   },
   {
      title: "Action",
      key: "action",
      width: 130,
      fixed: "right",

      render: () => (
         <Space size="middle">
            <EditTwoTone /> <DeleteTwoTone /> <EyeTwoTone />
         </Space>
      )
   }
];

export const BrandsTable = ({ data }: any) => {
   return (
      <Table
         pagination={false}
         dataSource={data}
         columns={columns}
         scroll={{ x: 1300 }}
         rowKey={(record) => record.id}></Table>
   );
};
