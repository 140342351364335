import React from "react";
import { Unit } from "../../../../hooks/useUnitFields";
import Form, { FormInstance } from "antd/lib/form";
import { Dimension, UnitFieldForm } from "./UnitFieldForm";
import { Col, InputNumber, Row } from "antd";

export type Conversion = {
   width: Dimension;
   height: Dimension;
   depth: Dimension;
   multiplier: number;
};

type Props = {
   unitFields: Unit[];
   form: FormInstance<any>;
   currentValues?: Conversion;
};

export function ConversionForm({ unitFields, form, currentValues }: Props) {
   return (
      unitFields && (
         <>
            <UnitFieldForm
               unitFields={unitFields}
               type="width"
               form={form}
               unit="m/lfm"
               picture={<img alt="width" src={"/width.png"} height="50px" />}
               current={currentValues?.width}
            />

            <UnitFieldForm
               unitFields={unitFields}
               type="height"
               form={form}
               unit="m²"
               picture={<img alt="height" src={"/height.png"} height="50px" />}
               current={currentValues?.height}
            />

            <UnitFieldForm
               unitFields={unitFields}
               type="depth"
               form={form}
               unit="m³"
               picture={<img alt="depth" src={"/depth.png"} height="50px" />}
               current={currentValues?.depth}
            />

            <Row gutter={16}>
               <Col span="9">
                  <Form.Item
                     label="Multiplier"
                     name={["conversion", "multiplier"]}>
                     <InputNumber type="number" step="any" />
                  </Form.Item>
               </Col>
            </Row>
         </>
      )
   );
}
