import React from "react";
import { FieldListViews } from "./FieldList";
import { Switch, Route } from "react-router-dom";
import { AddEditFields } from "./AddEditFields/index";

export const FieldsViews = () => {
   return (
      <Switch>
         <Route exact path="/forms/fields">
            <FieldListViews></FieldListViews>
         </Route>
         <Route path="/forms/fields/:operation">
            <AddEditFields></AddEditFields>
         </Route>
      </Switch>
   );
};
