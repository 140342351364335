import { Carousel, Descriptions, Drawer, Table, Tag, Image } from "antd";
import React, { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";

const getMaterialSpecification = (group_specification: any[]) => {
   return group_specification.map((item: any, index: number) => {
      const keys = Object.keys(item);

      return (
         <>
            {keys.map((key: any, index: number) => {
               const keyValue = item[key];

               if (typeof keyValue === "object") {
                  if ("unit" in keyValue && "unitValue" in keyValue) {
                     return (
                        <dl>
                           <dt>{key}</dt>
                           <dd>
                              {keyValue["unitValue"]} {keyValue["unit"]}
                           </dd>
                        </dl>
                     );
                  }
               } else {
                  return (
                     <dl>
                        <dt>{key}</dt>
                        <dd>{item[key]}</dd>
                     </dl>
                  );
               }
            })}
         </>
      );
   });
};

export const getMaterialReuseForTag = (tags: any[]) => {
   return tags.map((tag: any) => {
      return <Tag>{tag}</Tag>;
   });
};

const getPassportImages = (passport_images: any[]) => {
   return passport_images?.map((item: any, index: number) => {
      return (
         <Image src={item.object_url} key={index} height="150" width="100" />
      );
   });
};

export const MaterialRequestTable = ({ data, refetch }: any) => {
   console.log({ data });
   const [visible, setVisible] = useState(false);
   const [record, setRecords] = useState<any>(null);

   const columns: any = [
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
         fixed: "left"
      },
      {
         title: "Comments",
         dataIndex: "comments",
         key: "comments",
         fixed: "left"
      },
      {
         title: "Price Proposal",
         dataIndex: "price_proposal",
         key: "price_proposal",
         fixed: "left"
      },
      {
         title: "Quantity",
         dataIndex: "quantity",
         key: "quantity"
      },
      {
         title: "Created At",
         dataIndex: "created_at",
         key: "created_at"
      },
      {
         title: "Material Name",
         key: "material_name",
         render: (record: any) => {
            console.log({ record });
            return <span>{record?.material?.name}</span>;
         }
      },
      {
         title: "Action",
         key: "action",
         width: 130,
         fixed: "right",

         render: (record: any) => {
            return (
               <EyeOutlined
                  onClick={() => onViewMaterialRequestRecord(record)}
               />
            );
         }
      }
   ];

   const onViewMaterialRequestRecord = (record: any) => {
      setVisible(true);
      setRecords(record);
      console.log({ record });
   };

   const closeDrawer = () => {
      setVisible(false);
   };

   return (
      <>
         <Table
            pagination={false}
            dataSource={data}
            columns={columns}
            scroll={{ x: 1300 }}
            rowKey={(record) => record.id}></Table>
         <Drawer
            title="Material Request Details"
            placement="right"
            width={640}
            closable={true}
            onClose={closeDrawer}
            visible={visible}
            getContainer={false}
            style={{ position: "absolute" }}>
            <div style={{ marginBottom: "20px" }}>
               {record?.passport?.passport_images &&
                  Array.isArray(record.passport?.passport_images) &&
                  record.passport?.passport_images?.length && (
                     <Carousel autoplay style={{ width: "100px" }}>
                        {getPassportImages(record.passport?.passport_images)}
                     </Carousel>
                  )}
            </div>
            <Descriptions title="" layout="vertical" bordered>
               {record?.comments && (
                  <Descriptions.Item label="Comments">
                     {record.comments}
                  </Descriptions.Item>
               )}
               {record?.price_proposal && (
                  <Descriptions.Item label="Proposed Price By Requester">
                     {record.price_proposal} €
                  </Descriptions.Item>
               )}
               {record?.quantity && (
                  <Descriptions.Item label="Requested Quantity">
                     {record.quantity}
                  </Descriptions.Item>
               )}
               {record?.created_at && (
                  <Descriptions.Item label="Requested On">
                     {record.created_at}
                  </Descriptions.Item>
               )}
               {record?.email && (
                  <Descriptions.Item label="Requested By">
                     {record.email}
                  </Descriptions.Item>
               )}

               {record?.material && (
                  <>
                     <Descriptions.Item label="Material Name">
                        {record.material?.name}
                     </Descriptions.Item>

                     {record.material?.product_group?.label && (
                        <Descriptions.Item label="Product Group">
                           {record.material?.product_group?.label}
                        </Descriptions.Item>
                     )}

                     {record.material?.category && (
                        <Descriptions.Item label="Category">
                           {record.material?.category?.name}
                        </Descriptions.Item>
                     )}

                     {record.material?.amount && (
                        <Descriptions.Item label="Amount">
                           {record.material?.amount}
                        </Descriptions.Item>
                     )}

                     {record.material?.available_until_date && (
                        <Descriptions.Item label="Available Until">
                           {record.material?.available_until_date}
                        </Descriptions.Item>
                     )}
                     {record.material?.email && (
                        <Descriptions.Item label="Material Owner Email">
                           {record.material?.email}
                        </Descriptions.Item>
                     )}
                     {record.material?.is_available && (
                        <Descriptions.Item label="Is Material Available">
                           {record.material?.is_available ? "Yes" : "No"}
                        </Descriptions.Item>
                     )}
                     {record.material?.manufacturer && (
                        <Descriptions.Item label="Manufacturer">
                           {record.material?.manufacturer}
                        </Descriptions.Item>
                     )}
                     {record.material?.material_current_location && (
                        <Descriptions.Item label="Material Current Location">
                           {record.material?.material_current_location}
                        </Descriptions.Item>
                     )}
                     {record.material?.original_price && (
                        <Descriptions.Item label="Material Original Price">
                           {record.material?.original_price} € per /
                           {record.material?.product_unit}
                        </Descriptions.Item>
                     )}
                     {record.material?.weight_per_unit && (
                        <Descriptions.Item label="Material Weight Per Unit">
                           {record.material?.weight_per_unit}
                        </Descriptions.Item>
                     )}
                     {record.material?.group_specification &&
                        Array.isArray(record.material?.group_specification) &&
                        record.material?.group_specification.length && (
                           <Descriptions.Item label="Material Specification">
                              {getMaterialSpecification(
                                 record.material?.group_specification
                              )}
                           </Descriptions.Item>
                        )}

                     {record.material.material_reuse_details
                        ?.need_for_reuse && (
                        <Descriptions.Item label="Material Need for reuse">
                           {getMaterialReuseForTag(
                              record.material.material_reuse_details
                                 ?.need_for_reuse
                           )}
                        </Descriptions.Item>
                     )}

                     {record.material.material_reuse_details?.used_as && (
                        <Descriptions.Item label="Material reuse details">
                           {record.material.material_reuse_details?.used_as.map(
                              (item: any) => {
                                 return <Tag>{item}</Tag>;
                              }
                           )}
                        </Descriptions.Item>
                     )}
                  </>
               )}

               {record?.passport && (
                  <>
                     {record.passport.passport_public_identifier && (
                        <Descriptions.Item label="Passport Public identifier">
                           {record.passport?.passport_public_identifier}
                        </Descriptions.Item>
                     )}
                     {record.passport.custom_pollutance && (
                        <Descriptions.Item label="Custom  Pollutance">
                           {record.passport?.custom_pollutance}
                        </Descriptions.Item>
                     )}
                     {record.passport.deconstructability && (
                        <Descriptions.Item label="Deconstructability">
                           {record.passport?.deconstructability}
                        </Descriptions.Item>
                     )}
                     {record.passport.location_note && (
                        <Descriptions.Item label="Location Notes">
                           {record.passport?.location_note}
                        </Descriptions.Item>
                     )}
                     {record.passport.condition && (
                        <Descriptions.Item label="Condition">
                           {record.passport?.condition}
                        </Descriptions.Item>
                     )}
                  </>
               )}
            </Descriptions>
         </Drawer>
      </>
   );
};
