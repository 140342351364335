import React from "react";
import { Form, Switch } from "antd";
import * as Joi from "joi";

export const SwitchFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("switch")
   }).unknown()
});

export interface SwitchField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      [key: string]: any;
   };
}

export const SwitchField = (props: SwitchField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   return (
      <Form.Item {...formItemProps}>
         <Switch {...fieldProps}></Switch>
      </Form.Item>
   );
};
