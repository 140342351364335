import React, { useCallback, useEffect, useState } from "react";
import { Input, AutoComplete } from "antd";
import { SelectProps } from "antd/es/select";
import { Resource } from "../../../api/common";
import useAxios from "axios-hooks";

const buildOptionsElm = (results: any) => {
   return results.map((result: any) => {
      return {
         value: `${result.id}_${result.name}`,
         label: result.name
      };
   });
};

const BrandSearchField: React.FC<any> = ({ onFieldSelect }) => {
   const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
   const [searchTerm, setSearchTerm] = useState<any>("");

   const [{ data, loading, error }, refetch] = useAxios(
      { url: Resource.path.getBrands },
      { useCache: true }
   );

   useEffect(() => {
      if (data?.data.data) {
         const options = buildOptionsElm(data?.data.data);
         setOptions(options);
      }
   }, [data]);

   const handleSearch = (value: string) => {
      const brands = data?.data?.data;
      const filteredResults = brands.filter((item: any) => {
         return item?.name?.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      setOptions(buildOptionsElm(filteredResults));
   };

   const onSelect = useCallback((value: any) => {
      const [id, brandName] = value.split("_");
      setSearchTerm(brandName);
      onFieldSelect && onFieldSelect({ id, name: brandName });
   }, []);

   const onValueChange = (value: any) => {
      setSearchTerm(value);
   };

   return (
      <AutoComplete
         value={searchTerm}
         options={options}
         onChange={onValueChange}
         onSelect={onSelect}
         onSearch={handleSearch}>
         <Input.Search
            size="large"
            placeholder="Search brand by name"
            enterButton
         />
      </AutoComplete>
   );
};

export default BrandSearchField;
