import React, { useCallback, useEffect, useState } from "react";
import { Input, AutoComplete } from "antd";
import { SelectProps } from "antd/es/select";
import { Resource } from "../../../api/common";
import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";

const buildOptionsElm = (results: any) => {
   return results.map((result: any) => {
      return {
         value: `${result.id}_${result.name}`,
         label: result.name
      };
   });
};

const CategoriesSearchField: React.FC<any> = ({ onFieldSelect, value }) => {
   const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
   const { id }: any = useParams();

   const [searchTerm, setSearchTerm] = useState<any>("");

   const [{ data, loading, error }, refetch] = useAxios(
      { url: `${Resource.path.getCategoriesByTreeId}/${id}` },
      { useCache: false }
   );

   useEffect(() => {
      if (data?.data.data) {
         const options = buildOptionsElm(data?.data.data);
         setOptions(options);
      }
   }, [data]);

   useEffect(() => {
      if (value && value?.name) {
         setSearchTerm(value?.name);
      }
   }, [value]);

   const handleSearch = (value: string) => {
      const categories = data?.data?.data;
      const filteredResults = categories.filter((item: any) => {
         return item?.name?.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      setOptions(buildOptionsElm(filteredResults));
   };

   const onSelect = useCallback((value: any) => {
      const [id, categoryName] = value.split("_");
      setSearchTerm(categoryName);
      onFieldSelect && onFieldSelect({ id, name: categoryName });
   }, []);

   const onValueChange = (value: any) => {
      setSearchTerm(value);
   };

   return (
      <AutoComplete
         value={searchTerm}
         options={options}
         onChange={onValueChange}
         onSelect={onSelect}
         onSearch={handleSearch}>
         <Input.Search
            size="large"
            placeholder="Search categories by name"
            enterButton
         />
      </AutoComplete>
   );
};

export default CategoriesSearchField;
