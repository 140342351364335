export const GenericFieldTypeList: any = {
   text: {
      formItem: {
         label: "Text Field"
      },
      field: {
         type: "text"
      }
   },
   date: {
      formItem: {
         label: "Date Field"
      },
      field: {
         type: "date"
      }
   },
   number: {
      formItem: {
         label: "Number Field"
      },
      field: {
         type: "number",
         max: 100,
         min: 0
      }
   },
   switch: {
      formItem: {
         label: "Switch Field"
      },
      field: {
         type: "switch"
      }
   },
   select: {
      formItem: {
         label: "Select Field"
      },
      field: {
         type: "select",
         options: ["A", "B", "C", "add new options"]
      }
   },
   slider: {
      formItem: {
         label: "Slider"
      },
      field: {
         type: "slider",
         dots: false,
         max: 100,
         min: 0
      }
   },
   radio: {
      formItem: {
         label: "Radio"
      },
      field: {
         type: "radio",
         options: ["A", "B", "C", "add new options"]
      }
   },
   checkbox: {
      formItem: {
         label: "Checkbox"
      },
      field: {
         type: "checkbox",
         options: ["A", "B", "C", "add new options"]
      }
   },
   unitfield: {
      formItem: {
         label: "Unit Field"
      },
      field: {
         type: "unitfield",
         options: ["m2", "length", "kg"]
      }
   },
   colorswatchfield: {
      formItem: {
         label: "Color Swatch"
      },
      field: {
         type: "colorswatchfield",
      }
   }
};
